import React, { Component } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import "./DropdownSelector.scss";
class DropdownSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshKey: false
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.refreshDropdown !== this.props.refreshDropdown) {
      this.setState({
        refreshKey: !this.state.refreshKey
      })
    }
  }
  handleOnChange = (newOptions,actionData) => {
    this.props.onChange(newOptions,actionData)
  }
  render() {
    const {
      placeholder = "search",
      classes = "",
      styles,
      label = false,
      showBorder = false,
      loadOptions,
      value,
      fetching,
      refreshDropdown
    } = this.props;
    let selectDropdown = (
      <Select
        refresh={refreshDropdown}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        isLoading={fetching}
        value={value}
        isMulti
        options={loadOptions}
        isClearable
        isSearchable
        styles={styles} 
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={this.handleOnChange}
      />
    );
    return (
      <fieldset className={`type-head ${classes}`}>
        {label && (
          <label className="gray-text bold user-modal-label">{label}</label>
        )}
        {showBorder ? (
          <div className="select-box__border">{selectDropdown}</div>
        ) : (
          selectDropdown
        )}
      </fieldset>
    );
  }
}
const mapStateToProps = (state) => ({
  refreshDropdown: state.companies.refreshDropdown
});
export default connect(mapStateToProps,
  null)(DropdownSelector);

