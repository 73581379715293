import React, { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { forgotPassword } from "../../../redux/actions";
import { InputField } from "../../../components/Input";
import { errorToaster } from "../../../utils/loggerUtils";

import "../Form.scss";
import { Button } from "../../../components/Button";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            fetching: false,
            forgotPasswordSuccess: false
        };
    }
    changeHandler(e) {
        this.setState({ email: e.target.value });
    }

    submit(e) {
        e.preventDefault();
        const { email } = this.state;
        this.setState({ fetching: true });
        this.props
            .forgotPassword(email)
            .then((res) => {
                this.setState({ forgotPasswordSuccess: true });
                setTimeout(() => {
                    this.props.history.replace("/login");
                }, 4000);
            })
            .catch((err) => errorToaster(err))
            .finally((_) => this.setState({ fetching: false }));
    }

    renderForgotForm() {
        const { email, fetching } = this.state;
        return (
            <div className="form forgot-password">
                <h2 className="secondary-text form__heading">Forgot Your Password?</h2>
                <p className="form__sub-heading gray-text m-b-md m-t-xs">
                    It happens to the best of us! Enter the email associated with your account and
                    we'll get you taken care of!
                </p>
                <form className="login-form" onSubmit={this.submit.bind(this)}>
                    <div className="m-b-sm">
                        <InputField
                            onChange={this.changeHandler.bind(this)}
                            type="email"
                            required
                            label="EMAIL ADDRESS"
                            placeHolder="Email"
                            value={email}
                        />
                        <Link to={{ pathname: "/account/login" }} className="gray-text  f-14">
                            I remember! black to login.
                        </Link>
                    </div>
                    <div className="flex-center">
                        <Button
                            type="submit"
                            loading={fetching}
                            label="Submit"
                            loadingClass="green"
                            classes="out-line green big"
                        />
                    </div>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div className="flex-center flex-col">
                <h2 className="secondary-text form__heading">Got It!</h2>
                <p className="f-20 gray-text m-b-md m-t-xs center">
                    We'll be in touch with you shortly with your new password
                </p>
            </div>
        );
    }

    render() {
        const { forgotPasswordSuccess } = this.state;
        return forgotPasswordSuccess ? this.renderSuccessMessage() : this.renderForgotForm();
    }
}

const mapDispatchToProps = (dispatch) => ({
    forgotPassword: (email) => dispatch(forgotPassword(email))
});

export default connect(
    null,
    mapDispatchToProps
)(ForgotPassword);
