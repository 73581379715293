import React, { Component } from "react";

import { Button } from "../Button";
import { deleteAlert } from "../../assets/svg-icon";
import "./Dialog.scss";

class DialogContent extends Component {
  render() {
    const {
      icon = deleteAlert,
      title = "Delete Frame",
      text = "Are you sure you would like to delete this frame?",
      actionLabel = "Delete",
      actionClass = "red",
      loading = false,
      action,
      cancel,
      hideCancel = false
    } = this.props;

    return (
      <div className="rb-dialog__body-content flex-center flex-col">
        <img className="rb-dialog__body-content-icon" src={icon} alt="" />
        <h4 className="rb-dialog__body-content-title center m-b-xs">{title}</h4>
        <p className="rb-dialog__body-content-text f-18 center">{text}</p>
        <div className="inline-flex-center m-t-sm">
          <Button
            loading={loading}
            clickHandler={action}
            classes={`m-r-xs ${actionClass}`}
            label={actionLabel}
          />
          {!hideCancel && <Button clickHandler={cancel} label="Cancel" />}
        </div>
      </div>
    );
  }
}

export default DialogContent;
