import { combineReducers } from "redux";

import { AUTH_FAIL, SIGN_OUT } from "../actions";
import authReducer from "./authReducer";
import usersReducers from "./usersReducers";
import companiesReducer from "./companiesReducer";

const appReducer = combineReducers({
  auth: authReducer,
  users: usersReducers,
  companies: companiesReducer
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_FAIL || action.type === SIGN_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
