import { fetchUtil } from "../../utils/fetchUtils";
import { queryParams } from "../../utils/urlUtils";
import { errorToaster } from "../../utils/loggerUtils";
import { PaginationLimit } from "../../config/constants";
import { getToken } from "../../utils/tokenUtils";

export const USERS_DATA_REQUEST = "USERS_DATA_REQUEST";
export const USERS_DATA_SUCCESS = "USERS_DATA_SUCCESS";
export const USERS_DATA_FAIL = "USERS_DATA_FAIL";
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const IMPERSONATE_USER_REQUEST = "IMPERSONATE_USER_REQUEST";
export const IMPERSONATE_USER_SUCCESS = "IMPERSONATE_USER_SUCCESS";
export const IMPERSONATE_USER_FAIL = "IMPERSONATE_USER_FAIL";
export const CLEAR_USER_DATA_LISTING = "CLEAR_USER_DATA_LISTING";
export const SET_TEMP_ADD_USER_PAYLOAD = "SET_TEMP_ADD_USER_PAYLOAD";

// regenerate user's api key
export const USER_API_KEY_SUCCESS = "USER_API_KEY_SUCCESS";
export const CHANGE_IMPERSONATE_MODAL_VIEW = "CHANGE_IMPERSONATE_MODAL_VIEW";

const limit = PaginationLimit.ROWS_LIMIT;

export const setTempAddUserPayload = payload => ({
    type: SET_TEMP_ADD_USER_PAYLOAD,
    payload
})

export const getUsersData = (params, fetchOnly = false) => (dispatch) => {
    if (!fetchOnly) dispatch({ type: USERS_DATA_REQUEST });

    const queryParamsConfigs = { ...params, Limit: limit };
    if (fetchOnly) delete queryParamsConfigs.Limit;
    return fetchUtil({
        url: queryParams("/users",queryParamsConfigs),
        token: localStorage.RAToken
    })
        .then((res) => {
            if (!fetchOnly) dispatch({ type: USERS_DATA_SUCCESS, payload: res });
            return res;
        })
        .catch((err) => {
            if (!fetchOnly) dispatch({ type: USERS_DATA_FAIL, payload: err.Message });
            return err;
        });
};

export const createUser = (data, method) => (dispatch) => {
    dispatch({ type: CREATE_USER_REQUEST });
    return new Promise((resolve, reject) => {
        fetchUtil({
            url: "/user",
            method: method || "POST",
            token: localStorage.RAToken,
            body: JSON.stringify(data)
        })
            .then((res) => {
                method === "PUT"
                    ? dispatch({ type: UPDATE_USER_SUCCESS, payload: data })
                    : dispatch({ type: CREATE_USER_SUCCESS, payload: res });
                resolve(res);
            })
            .catch((err) => {
                dispatch({ type: CREATE_USER_FAIL, payload: err.Message });
                reject(err);
            });
    });
};

export const updateUserSegments = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        fetchUtil({
            url: "/allow/segment",
            method: "POST",
            token: localStorage.RAToken,
            body: JSON.stringify(data)
        })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                dispatch({ type: CREATE_USER_FAIL, payload: err.Message });
                reject(err);
            });
    });
};

export const deleteUser = (id) => (dispatch) => {
    dispatch({ type: DELETE_USER_REQUEST });
    return new Promise((resolve, reject) => {
        fetchUtil({
            url: `/user/${id}`,
            method: "DELETE",
            token: localStorage.RAToken
        })
            .then((res) => {
                dispatch({ type: DELETE_USER_SUCCESS, payload: res });
                resolve(res);
            })
            .catch((err) => {
                dispatch({ type: DELETE_USER_FAIL, payload: err.Message });
                errorToaster(err);
                reject(err);
            });
    });
};

export const getImpersonateUser = (token) => (dispatch, getState) => {
    return fetchUtil({
        url: "/me",
        token
    }).then((res) => {
        return Promise.resolve(res);
    });
};

export const impersonate = (id, appId) => (dispatch) => {
    dispatch({ type: IMPERSONATE_USER_REQUEST });
    return new Promise((resolve, reject) => {
        fetchUtil({
            url: `/impersonate/${id}/app/${appId}`,
            token: localStorage.RAToken
        })
            .then((res) => {
                dispatch({ type: IMPERSONATE_USER_SUCCESS });
                resolve(res);
            })
            .catch((err) => {
                dispatch({ type: IMPERSONATE_USER_FAIL });
                errorToaster(err);
                reject(err);
            });
    });
};

export const clearUserDataListing = () => {
    return {
        type: CLEAR_USER_DATA_LISTING
    };
};

export const regenerateApiKey = (id) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/api_key/${id}`,
        token,
        method: "PUT"
    })
        .then((res) => {
            dispatch({
                type: USER_API_KEY_SUCCESS,
                payload: { apiKey: res.ApiKey, id: res.Id }
            });
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err);
            return Promise.reject(err);
        });
};

export const changeImpersonateModalView = (show) => {
    return { type: CHANGE_IMPERSONATE_MODAL_VIEW, payload: show };
};
