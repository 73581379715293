import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import createEncryptor from "redux-persist-transform-encrypt";

import Config from "../config";
import reducers from "./reducers";
import { SIGN_OUT } from "./actions";

const encryptor = createEncryptor({
  secretKey: Config.PERSIST_SECRET_KEY,
  onError: error => {
    console.log("error while encrypting", error);
  }
});

const persistConfig = {
  key: "user",
  storage,
  whitelist: ["auth"],
  stateReconciler: autoMergeLevel2,
  transforms: [encryptor]
};

const persistedReducer = persistReducer(persistConfig, reducers);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = applyMiddleware(thunk);

const storeFactory = () => {
  let store = createStore(persistedReducer, composeEnhancers(middleware));
  let persistor = persistStore(store);

  const listener = e => {
    const { storageArea } = e;
    if (store.getState().auth.isAuthenticated) {
      if (!storageArea.hasOwnProperty("RAToken")) {
        store.dispatch({
          type: SIGN_OUT
        });
        localStorage.clear();
      }
    }
  };
  window.addEventListener("storage", listener);

  return { store, persistor };
};

let { store, persistor } = storeFactory();

export { store, persistor };
