import React, { Component } from "react";

import "./Sidebar.scss";
import { logOut } from "../../assets/svg-icon";

class Sidebar extends Component {
    render() {
        const { children, onLogoutClick } = this.props;
        return (
            <aside className="sidebar relative">
                <div className="sidebar-fixed-container fancy-scroll">
                    <div className="sidebar-container">{children}</div>
                    <div className="sidebar-btn" onClick={onLogoutClick}>
                        <span className="icon">
                            <img src={logOut} alt="logout icon" />
                        </span>
                        <span className="text">Log out</span>
                    </div>
                </div>
            </aside>
        );
    }
}

export default Sidebar;
