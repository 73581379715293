import { fetchUtil } from "../../utils/fetchUtils";
import { queryParams } from "../../utils/urlUtils";
import { errorToaster } from "../../utils/loggerUtils";
import { PaginationLimit } from "../../config/constants";
import Config from "../../config";
import { handleFetchError } from "../../utils/errorHandlerUtils";

export const COMPANIES_DATA_REQUEST = "COMPANIES_DATA_REQUEST";
export const COMPANIES_DATA_SUCCESS = "COMPANIES_DATA_SUCCESS";
export const COMPANIES_DATA_FAIL = "COMPANIES_DATA_FAIL";
export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAIL = "CREATE_COMPANY_FAIL";
export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL";
export const DEFAULT_COMPANIES_DATA_REQUEST = "DEFAULT_COMPANIES_DATA_REQUEST";
export const DEFAULT_COMPANIES_DATA_SUCCESS = "DEFAULT_COMPANIES_DATA_SUCCESS";
export const DEFAULT_COMPANIES_DATA_FAIL = "DEFAULT_COMPANIES_DATA_FAIL";
export const CLEAR_COMPANIES_DATA_LISTING = "CLEAR_COMPANIES_DATA_LISTING";
export const TIME_ZONE_SUCCESS = "TIME_ZONE_SUCCESS";
export const REFRESH_DROPDOWN_OPTIONS = "REFRESH_DROPDOWN_OPTIONS";
export const FETCHING_COMPANY_STATUS = "FETCHING_COMPANY_STATUS";
export const SET_WEBCHAT_FETCHING = "SET_WEBCHAT_FETCHING";
const limit = PaginationLimit.ROWS_LIMIT;

export const getCompaniesData =
    (params = {}) =>
        (dispatch) => {
            dispatch({ type: COMPANIES_DATA_REQUEST });
            fetchUtil({
                url: queryParams("/company", { ...params, Limit: limit }),
                token: localStorage.RAToken
            })
                .then((res) => {
                    dispatch({ type: COMPANIES_DATA_SUCCESS, payload: res });
                    if (!params.Page || params.Page < 2) {
                        dispatch({ type: DEFAULT_COMPANIES_DATA_SUCCESS, payload: res });
                    }
                })
                .catch((err) => {
                    dispatch({ type: COMPANIES_DATA_FAIL, payload: err.Message });
                });
        };

export const getDefaultCompanies = (params) => (dispatch, getState) => {
    return new Promise((resolve) => {
        const query = params || {};
        const defaultDropDownCompanies = getState().companies.defaultDropDownCompanies;
        if (!params && defaultDropDownCompanies.length) {
            resolve(defaultDropDownCompanies);
        } else if (params && defaultDropDownCompanies.length < limit) {
            resolve(defaultDropDownCompanies);
        } else {
            dispatch({ type: DEFAULT_COMPANIES_DATA_REQUEST });
            fetchUtil({
                url: queryParams("/company", { ...query, Limit: limit }),
                token: localStorage.RAToken
            })
                .then((res) => {
                    dispatch({ type: DEFAULT_COMPANIES_DATA_SUCCESS, payload: res });
                    resolve(res.Companies);
                })
                .catch((err) => {
                    dispatch({ type: COMPANIES_DATA_FAIL, payload: err.Message });
                    resolve([]);
                });
        }
    });
};

export const getComapnyTimeZones = () => (dispatch, getState) => {
    return new Promise((resolve) => {
        let defaultTimeZones = getState().companies.companyTimeZones;

        if (defaultTimeZones.length) {
            resolve(defaultTimeZones);
        } else {
            fetchUtil({
                url: "/time_zones",
                token: localStorage.RAToken
            })
                .then((res) => {
                    dispatch({ type: TIME_ZONE_SUCCESS, payload: res });
                    resolve(res.TimeZones);
                })
                .catch((err) => {
                    resolve([]);
                });
        }
    });
};

export const setCompany = (data, method) => (dispatch) => {
    dispatch({ type: CREATE_COMPANY_REQUEST });
    console.log("saving company...")
    return new Promise((resolve, reject) => {
        if (!data.WebChatAccess) {
            data.Apps = data.Apps.filter((app) => app !== 4);
        } else {
            data.Apps = data.Apps && data.Apps.length ? [...data.Apps.filter(app => app !== 4), 4] : [4];
        }
        fetchUtil({
            url: "/company",
            method: method || "POST",
            token: localStorage.RAToken,
            body: JSON.stringify(data)
        })
            .then((res) => {
                method === "PUT"
                    ? dispatch({ type: UPDATE_COMPANY_SUCCESS, payload: data })
                    : dispatch({ type: CREATE_COMPANY_SUCCESS, payload: res });
                resolve(res);
            })
            .catch((err) => {
                dispatch({ type: CREATE_COMPANY_FAIL, payload: err.Message });
                errorToaster(err);
                reject(err);
            });
    });
};

export const deleteCompany = (id) => (dispatch) => {
    dispatch({ type: DELETE_COMPANY_REQUEST });
    return new Promise((resolve, reject) => {
        fetchUtil({
            url: `/company/${id}`,
            method: "DELETE",
            token: localStorage.RAToken
        })
            .then((res) => {
                dispatch({ type: DELETE_COMPANY_SUCCESS, payload: res, id });
                resolve(res);
            })
            .catch((err) => {
                dispatch({ type: DELETE_COMPANY_FAIL, payload: err.Message });
                errorToaster(err);
                reject(err);
            });
    });
};

export const clearCompaniesDataListing = () => {
    return {
        type: CLEAR_COMPANIES_DATA_LISTING
    };
};

export const orderLandlinePhone = (number) => (dispatch, getState) => {
    return fetchUtil({
        url: "/landline_phone",
        token: localStorage.RAToken,
        method: "POST",
        body: JSON.stringify({ Number: number })
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err);
            return Promise.reject(err);
        });
};

export const generateTollFreeNumber = (number) => (dispatch, getState) => {
    return fetchUtil({
        url: "/toll_free_number",
        method: "POST",
        token: localStorage.RAToken,
        body: JSON.stringify({ Number: number })
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err);
            return Promise.reject(err);
        });
};

export const generateHostNumber = (number) => (dispatch, getState) => {
    return fetchUtil({
        url: "/hosted_number",
        method: "POST",
        token: localStorage.RAToken,
        body: JSON.stringify({ Number: number })
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err);
            return Promise.reject(err);
        });
};

export const uploadImage = (data) => (dispatch, getState) => {
    const body = new FormData();
    body.append("file", data);

    return fetch(`${Config.env().API_URL}/media`, {
        method: "POST",
        body,
        headers: {
            Authorization: localStorage.RAToken
        },
        credentials: "include"
    })
        .then(handleFetchError)
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getCompanyFormFields = (companyId, formId) => (dispatch, getState) => {
    return fetchUtil({
        url: `/company/form/fields?CompanyId=${companyId}&FormId=${formId}`,
        method: "GET",
        token: localStorage.RAToken
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err);
            return Promise.reject(err);
        });
};
export const getCompanyById = (companyId) => (dispatch, getState) => {
    dispatch({ type: FETCHING_COMPANY_STATUS, payload: true });
    return fetchUtil({
        url: `/company/${companyId}`,
        method: "GET",
        token: localStorage.RAToken
    })
        .then((res) => {
            dispatch({ type: FETCHING_COMPANY_STATUS, payload: false });
            return Promise.resolve(res);
        })
        .catch((err) => {
            dispatch({ type: FETCHING_COMPANY_STATUS, payload: false });
            errorToaster(err);
            return Promise.reject(err);
        });
};

export const getWebchatsById = (companyId) => (dispatch, getState) => {
    dispatch({ type: SET_WEBCHAT_FETCHING, payload: true });
    return fetchUtil({
        url: `/webchat/widget/${companyId}`,
        method: "GET",
        token: localStorage.RAToken
    })
        .then((res) => {
            dispatch({ type: SET_WEBCHAT_FETCHING, payload: false });
            return Promise.resolve(res);
        })
        .catch((err) => {
            dispatch({ type: SET_WEBCHAT_FETCHING, payload: false });
            errorToaster(err);
            return Promise.reject(err);
        });
};

export const updateWebchats = (data) => (dispatch, getState) => {
    try {
        console.log(data, "DATA")
        dispatch({ type: SET_WEBCHAT_FETCHING, payload: true });
        return fetchUtil({
            url: `/webchat/widget`,
            method: "PUT",
            token: localStorage.RAToken,
            body: JSON.stringify(data)
        })
            .then((res) => {
                dispatch({ type: SET_WEBCHAT_FETCHING, payload: false });
                return Promise.resolve(res);
            })
            .catch((err) => {
                dispatch({ type: SET_WEBCHAT_FETCHING, payload: false });
                errorToaster(err);
                return Promise.reject(err);
            });
    } catch (error) {
        console.log("error on updateWebchats:companiesActions.js", error)
        errorToaster(error);
    }
};

export const updateCompanyFormFields = (companyId, formId, slug) => (dispatch, getState) => {
    return fetchUtil({
        url: `/company/form/fields`,
        method: "PUT",
        token: localStorage.RAToken,
        body: JSON.stringify({ CompanyId: companyId, FormId: formId, FormFieldId: slug })
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err);
            return Promise.reject(err);
        });
};

export const refreshDropdown = () => (dispatch, getState) => {
    dispatch({ type: REFRESH_DROPDOWN_OPTIONS });
    return true;
};
