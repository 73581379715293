import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AdminRoutes from "../Admin/router";
import AdminMenus from "../../components/Sidebar/AdminMenus";
import Sidebar from "../../components/Sidebar/Sidebar";
import { menuWhite } from "../../assets/svg-icon";

import "./Dashboard.scss";
import { IconButton } from "../../components/Button/";
import { signOut } from "../../redux/actions";
import { LayoutLoading } from "../../components/Loading";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            isLoading: false
        };
        this.menuToggle = this.menuToggle.bind(this);
    }

    menuToggle() {
        this.setState({
            menuOpen: !this.state.menuOpen
        });
    }

    handleLogoutClick = () => {
        this.setState({ isLoading: true });
        this.props.signOut().catch((err) => this.setState({ isLoading: false }));
    };

    render() {
        const { isAuthenticated } = this.props,
            menuOpen = this.state.menuOpen;

        if (!isAuthenticated) {
            return <Redirect to="/account/login" />;
        }

        const routes = AdminRoutes,
            redirect = "/dashboard/users";

        return isAuthenticated ? (
            <div className={`dashboard ${menuOpen ? "sidebar-active" : ""}`}>
                {this.state.isLoading && <LayoutLoading parentClass="fixed" />}

                <header className="sidebar-toggle-btn hidden sm-visible">
                    <IconButton icon={menuWhite} clickHandler={this.menuToggle} />
                </header>

                <div className="flex-stretch">
                    <div className="overlay-sidebar" onClick={this.menuToggle}>
                        &nbsp;
                    </div>
                    <Sidebar onLogoutClick={this.handleLogoutClick}>
                        <AdminMenus />
                    </Sidebar>
                    <main className="flex-grow main-content">
                        <Switch>
                            {routes.map((route, index) => {
                                return route.component ? (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        render={(props) => <route.component {...props} />}
                                    />
                                ) : null;
                            })}
                            <Redirect to={redirect} />
                        </Switch>
                    </main>
                </div>
            </div>
        ) : null;
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);
