import React from "react";
import { toast } from "react-toastify";

import { ToasterMessage } from "../components/ToasterMessage";

export const logger = (msg) => {
    if (process.env.NODE_ENV === "development") {
        console.log("logger...", msg);
    }
};

export const errorToaster = (msg) => {
    toast(<ToasterMessage text={msg.Message || "Something went wrong!"} icon="close" />, {
        containerId: "error",
        toastId: 1
    });
};

const customToaster = () => {
    let toastId = null;
    return (text) => {
        if (!toast.isActive(toastId)) {
            toastId = toast(<ToasterMessage text={text} />, { containerId: "success" });
        }
    };
};

export const toaster = customToaster();
