let Config = {
  PERSIST_SECRET_KEY: "click123$$$",
  ENVIRONMENT: "PRODUCTION",
  ENVIRONMENTS: {
    LOCAL: {
      API_URL: "https://api-admin.digitalrange.com",
      BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/digital-range-connect",
      WEBCHAT_URL_DOMAIN: "https://widget.digitalrange.com/"
    },
    DEVELOPMENT: {
      API_URL: "https://api-admin.digitalrange.com",
      BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/digital-range-connect",
      WEBCHAT_URL_DOMAIN: "https://widget.digitalrange.com/"
    },
    PRODUCTION: {
      API_URL: "https://api-admin.digitalrange.com",
      BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/digital-range-connect",
      WEBCHAT_URL_DOMAIN: "https://widget.digitalrange.com/"
    }
  }
};

Config.env = () => {
  return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;