export const COMPANY_TIME_ZONE = {
    PACIFIC_TIME: 1,
    MOUNTAIN_TIME: 2,
    CENTRAL_TIME: 3,
    EASTERN_TIME: 4
};

export const TIME_ZONE_AREA_NAME = {
    [COMPANY_TIME_ZONE.PACIFIC_TIME]: "US Pacific Time",
    [COMPANY_TIME_ZONE.MOUNTAIN_TIME]: "US Mountain Time",
    [COMPANY_TIME_ZONE.CENTRAL_TIME]: "US Central Time",
    [COMPANY_TIME_ZONE.EASTERN_TIME]: "US Eastern Time"
};
