import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import "../Form.scss";
import { InputField } from "../../../components/Input";
import { login } from "../../../redux/actions";
import { Button } from "../../../components/Button";
import { DialogModal, ImpersonateDialogContent } from "../../../components/Dialog";
import { changeImpersonateModalView } from "../../../redux/actions";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectDashboard: false,
            email: "",
            password: ""
        };
    }

    changeHandler(e) {
        this.setState({ [e.target.dataset.field]: e.target.value });
    }

    submit = (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        this.setState({ request: true });
        this.props.login(email, password);
    };

    handleAccountSelectClick = (appId) => {
        const { email, password } = this.state;
        this.props.login(email, password, appId);
    };

    render() {
        const { redirectDashboard, email, password } = this.state;
        const { fetching, showImpersonateModal } = this.props;

        if (redirectDashboard) {
            return <Redirect to="/dashboard/overview" />;
        }

        return (
            <div className="form login">
                <h2 className="secondary-text form__heading">
                    Log in to Range <span className="uppercase bold">Admin</span>
                </h2>
                <p className="form__sub-heading gray-text m-b-md m-t-xs">
                    Please enter your credentials to proceed.
                </p>
                <form className="login-form" onSubmit={this.submit}>
                    <div className="m-b-sm">
                        <InputField
                            id="email"
                            type="email"
                            label="EMAIL ADDRESS"
                            placeHolder="Email"
                            data-field="email"
                            value={email}
                            required
                            onChange={this.changeHandler.bind(this)}
                        />
                    </div>
                    <div className="relative m-b-sm">
                        <Link
                            to={{ pathname: "/account/forgotpassword" }}
                            className="gray-text forgot-link f-14"
                        >
                            Forgot password ?
                        </Link>
                        <InputField
                            id="password"
                            type="password"
                            label="PASSWORD"
                            placeHolder="Password"
                            data-field="password"
                            value={password}
                            required
                            onChange={this.changeHandler.bind(this)}
                        />
                    </div>
                    <div className="flex-center">
                        <Button
                            type="submit"
                            loading={fetching}
                            label="login"
                            loadingClass="green"
                            classes="out-line green big"
                        />
                    </div>
                </form>
                {showImpersonateModal && (
                    <DialogModal
                        classes="impersonate"
                        closeDialog={() => {
                            this.props.changeImpersonateModalView(false);
                        }}
                    >
                        <ImpersonateDialogContent
                            title="Please Select an account"
                            onImpersonateDialogButtonClick={this.handleAccountSelectClick}
                            isLoading={fetching}
                        />
                    </DialogModal>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: state.auth.fetching,
    showImpersonateModal: state.users.showImpersonateModal
});

const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, password, appId) => dispatch(login(email, password, appId)),
        changeImpersonateModalView: (show) => dispatch(changeImpersonateModalView(show))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
