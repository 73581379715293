import Config from "../config";

export const getRandomId = () => (((1 + Math.random()) * 0x1000000) | 0).toString(16).substring(1);


export const validateUSPhoneNumber = (phoneNumber) => {
    let NumberRegex = new RegExp(/^[+]1[0-9]{3,14}$/);  // allow only US numbers starting with +1
    return NumberRegex.test(phoneNumber) && (phoneNumber.length >= 6 && phoneNumber.length <= 13);
}
export const getScript = (ApiKey) => {
    return `<script type="text/javascript">
  (function(scope, doc, tagName, src, objectName, newElement, firstElement) {
    Array.isArray(scope["LongDriveAgency"]) ?
    scope["LongDriveAgency"].push(objectName) :
    (scope["LongDriveAgency"] = [objectName]);
    scope[objectName] =
    scope[objectName] ||
    function() {
    scope[objectName].queries = scope[objectName].queries || [];
    scope[objectName].queries.push(arguments);
    };
    scope[objectName].scriptInjectedAt = 1 * new Date();
    newElement = doc.createElement(tagName);
    newElement.setAttribute("id", "long-drive-agency-widget-script");
    firstElement = doc.getElementsByTagName(tagName)[0];
    newElement.async = 1;
    newElement.src = src;
    firstElement
    ?
    firstElement.parentNode.insertBefore(newElement, firstElement) :
    doc.getElementsByTagName("head")[0].appendChild(newElement);
    })(window, document, "script", "${Config.env().WEBCHAT_URL_DOMAIN}longdriveagency.js?v=1414556766776095155192326&api_key=${ApiKey}", "longDriveAgency");
    longDriveAgency("Init", "${ApiKey}");
    </script>`

}