import Users from "./Users/Users";
import Companies from "./Companies/Companies";


const routes = [
    {
        path: "/dashboard/users",
        name: "users",
        component: Users,
        exact: true,
        admin : true
    },
    {
        path: "/dashboard/companies",
        name: "companies",
        component: Companies,
        exact: true,
        admin : true
    }
];

export default routes;
