import React, { Component } from "react";
import { checkboxTick } from "../../../assets/svg-icon";
import "./CheckBox.scss";
import { getRandomId } from "../../../utils/commonUtils";

class CheckBox extends Component {
    constructor(props) {
        super(props);
        this.randomId = getRandomId();
    }

    renderLabel() {
        const { label, id = this.randomId } = this.props;
        return label ? (
            <label htmlFor={id} className="checkbox__label">
                {label}
            </label>
        ) : null;
    }

    render() {
        const { label, classes, changeHandler, checked, id = this.randomId , disabled = false } = this.props;

        return (
            <span className={`checkbox ${classes}`}>
                <input
                    onChange={changeHandler}
                    id={id}
                    className="checkbox__input"
                    type="checkbox"
                    name="check"
                    checked={checked}
                    disabled={disabled}
                />
                <label htmlFor={id} className="checkbox__box">
                    <img className="icon-check" src={checkboxTick} alt="" />
                </label>
                {this.renderLabel()}
            </span>
        );
    }
}

export default CheckBox;
