import Config from "../config";
import { handleFetchError } from "./errorHandlerUtils";
import { errorToaster } from "./loggerUtils";

export const fetchUtil = (data) => {
  const { url, method = "GET", token = null, body = null, toastErrorMessage } = data;
  let headers = { "Content-Type": "application/json" };
  if (token) {
    headers = { ...headers, Authorization: token };
  }
  return fetch(`${Config.env().API_URL}${url}`, {
    method,
    headers,
    body,
    credentials: "include"
  })
    .then(handleFetchError)
    .catch((error) => {
      if (toastErrorMessage) {
        errorToaster(error)
      }
      console.log(error)
    })
};
