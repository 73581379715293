import React, { Component } from "react";
import { connect } from "react-redux";
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import CreatableSelect from "react-select";

import "../Users/Users.scss";
import "./Companies.scss";
import { Button, DropdownButton } from "../../../components/Button";
import { Table, HeadTr } from "../../../components/Table";
import Pagination from "../../../components/Pagination/Pagination";
import { withRouter } from 'react-router-dom';
import { addWhite, EnableLandline, spinnerWhite } from "../../../assets/svg-icon";


import {
  InputField,
  // RadioButton,
  CompRadioButton,
  SearchField,
  TypeHead,
  CheckBox,
  ButtonInput,
  ImageUploader,
  CompCheckBox
} from "../../../components/Input";
import { DialogModal, Dialog, DialogContent } from "../../../components/Dialog";
import {
  setCompany,
  deleteCompany,
  getCompaniesData,
  clearCompaniesDataListing,
  getComapnyTimeZones,
  orderLandlinePhone,
  generateTollFreeNumber,
  uploadImage,
  getCompanyFormFields,
  updateCompanyFormFields,
  generateHostNumber,
  setTempAddUserPayload,
  getUsersData,
  getCompanyById,
  updateUserSegments,
  createUser,
  getDefaultCompanies,
  refreshDropdown,
} from "../../../redux/actions";
import { Spinner, LayoutLoading } from "../../../components/Loading";
// import {  } from "../../../config/constants";

import {
  PaginationLimit,
  ApplicationTypes,
  CompanyPhoneStatus,
  CompanyPhoneTypes,
  CompanyDefaultSegments,
  FrameRequestTypes,
  OptRequestTypes,
  BrandedRequestTypes

} from "../../../config/constants";

import { TIME_ZONE_AREA_NAME, COMPANY_TIME_ZONE } from "../../../config/constants/CompanyTimeZones";
import { toaster, errorToaster } from "../../../utils/loggerUtils";
import { concatBaseMediaUrl } from "../../../utils/imageUtils";
import { FormFieldSlug, FormIds, SegmentType } from "../../../constraints";
import MmsRequestTypes from "../../../config/constants/MmsRequestTypes";
import DropdownSelector from "../../../components/Input/DropdownSelector/DropdownSelector";
import { validateUSPhoneNumber } from "../../../utils/commonUtils";
import BrandStatus from "../../../config/constants/BrandStatus";
import Select from "react-select";
import WebChatSection from './WebChatSection';
import PhoneTypeRadioButton from "../../../components/Input/RadioButton/PhoneTypeRadioButton";
import EmailServiceAccessTypes from "../../../config/constants/EmailServiceAccessTypes";

const rowLimit = PaginationLimit.ROWS_LIMIT,
  dialogContent = {
    title: "Delete Company",
    text: "Are you sure you would like to delete this company?",
    actionLabel: "Delete",
    actionClass: "red"
  };

let searchingText = "",
  deleteCompanyId = null,
  searchQuery = false;

const USER_TYPE = {
  ADMIN: 1,
  USER: 2,
  SUBADMIN: 3,
};

const optionsForCreate = [{ value: 1, label: "Admin" }, { value: 2, label: "User" }, { value: 3, label: "Club Admin" }];

const customStyles = (type) => ({
  control: (provided) => ({
    ...provided,
    width: "100%",
    border: "1px solid #a7b9c5",
    borderRadius: "4px",
    backgroundColor: "#fff",
    color: "#a7b9c5",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: "16px",
    minHeight: "36px",
    padding: "2px",
    ...(type === "segments" ? {
      minHeight: "103px",
      alignItems: "flex-start",
    } : {}),
    "&:hover": { borderColor: "#abbefc" },
  }),
  multiValue: (provided) => ({
    ...provided,
    lineHeight: 1.7
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    ...(type === "timezone" ? {
      display: "none",
    } : {}),
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    ...(type === "timezone" ? {
      display: "none",
    } : {}),
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    ...(type === "segments" || type === "segments2" ? {
      display: "none",
    } : {}),
  }),
  input: (provided) => ({
    ...provided,
    ...(type === "segments2" ? {
      marginTop: "-9px",
    } : {}),
  }),
  placeholder: (provided) => ({
    ...provided,
    ...(type === "segments2" ? {
      top: "20%",
    } : {}),
    ...(type === "timezone" ? {
      color: "#a7b9c5",
      fontFamily: "Montserrat",
      fontSize: "13px",
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "18px",
    } : {}),
  }),
});

const components = {
  DropdownIndicator: null
};

const IsCompanyUser = (type) => {
  return (type === USER_TYPE.USER || type === USER_TYPE.SUBADMIN)
}

const filterOptions = (companies, query) => {
  const companiesData = companies.map((comapny) => {
    return { ...comapny, label: comapny.Name };
  });
  if (query)
    return companiesData.filter((i) => i.label.toLowerCase().includes(query.toLowerCase()));
  else return companiesData;
};

class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userPayload: {},
      userSelect: null,
      addCompanyDialog: false,
      addUserDialog: false,
      usersData: null,
      deleteCompanyDialog: false,
      editCompany: null,
      searching: "",
      companyPayload: {},
      selectedTimeZone: "",
      segmentText: "",
      phoneLoading: false,
      hostNumberLoading: false,
      tollNumberLoading: false,
      imageObj: null,
      imageUploading: false,
      copied: false,
      // webChatAccess: false,
      params: {
        Page: 1
      },
      allowedSlugs: [],
      lastValidPhoneNumber: {},
      lastEditedPhoneNumberType: null,
      webChatLoading: false
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onUserChangeHandler = this.onUserChangeHandler.bind(this);
    this.getCompanyUsers = this.getCompanyUsers.bind(this);
  }
  getCompanyFormData = (companyId) => {
    const { getCompanyFormFields } = this.props;
    getCompanyFormFields(companyId, FormIds.OnBoard).then((val) => {
      let formFields = val.FormFields;
      formFields = formFields.map((slugsObj) => {
        return slugsObj.FormFieldId.Slug;
      })
      this.setState({
        allowedSlugs: formFields
      })
    })
  }
  handleWebChatAccess = () => {
    this.setState({
      ...this.state,
      companyPayload: { ...this.state.companyPayload, WebChatAccess: this.state.companyPayload.WebChatAccess ? 0 : 1 }

    })
  }
  updateCompanyFormData = (companyId) => {
    const { updateCompanyFormFields } = this.props;
    updateCompanyFormFields(companyId, FormIds.OnBoard, this.state.allowedSlugs).then((val) => {
      return true;
    })
  }
  componentDidMount() {
    this.props.getCompaniesData();
    this.props.getUsersData({}, true).then(res => {
      this.setState({
        usersData: res.Users,
      });
    })
  }
  componentWillUnmount() {
    this.props.clearCompaniesDataListing();
  }

  onUserChangeHandler(e) {
    let numberKeys = ["FrameRequest", "OptStatusRequest", "MMSStatus", "BrandedFooterRequest"];
    const key = e.target.dataset.field;
    let val = e.target.value,
      userPayload = { ...this.state.userPayload };
    userPayload[key] = numberKeys.includes(key) ? parseInt(val) : val;
    this.setState({ userPayload: userPayload });
  }

  onChangeHandler(e, buttonType) {
    let numberKeys = ["FrameRequest", "OptStatusRequest", "MMSStatus", "BrandedFooterRequest", "EmailServiceAccess"];
    const key = e.target.dataset.field;

    let val = e.target.value,
      companyPayload = { ...this.state.companyPayload };
    companyPayload[key] = numberKeys.includes(key) ? parseInt(val) : val;

    this.setState({ companyPayload, lastEditedPhoneNumberType: buttonType ? buttonType : "" });
  }

  onPhoneTypeChange(phoneType) {
    let companyPayload = { ...this.state.companyPayload };
    companyPayload.PhoneType = phoneType
    this.setState({ companyPayload });
  }

  handleCheckboxChange = (e, type) => {
    let companyPayload = {
      ...this.state.companyPayload,
      ...(this.state.companyPayload.Apps && { Apps: [...this.state.companyPayload.Apps] })
    };

    if (!companyPayload.Apps) {
      companyPayload.Apps = [];
    }
    let currentAppIndex = companyPayload.Apps.findIndex((data) => {
      if (typeof data === "object") {
        return data.Id === type;
      }
      return data === type;
    });
    if (e.target.checked) {
      companyPayload.Apps.push(type);
    } else {
      companyPayload.Apps.splice(currentAppIndex, 1);
    }
    this.setState({ companyPayload });
  };

  handleUserCheckboxChange = (e, type) => {
    let userPayload = {
      ...this.state.userPayload,
      ...(this.state.userPayload.Apps && { Apps: [...this.state.userPayload.Apps] })
    };

    if (!userPayload.Apps) {
      userPayload.Apps = [];
    }
    let currentAppIndex = userPayload.Apps.findIndex((data) => {
      if (typeof data === "object") {
        return data.Id === type;
      }
      return data === type;
    });
    if (e.target.checked) {
      userPayload.Apps.push(type);
    } else {
      userPayload.Apps.splice(currentAppIndex, 1);
    }
    this.setState({ userPayload });
  };

  loadOptions = (query, callback) => {
    this.props.getComapnyTimeZones().then((res) => {
      callback(this.filterOptions(res, query));
    });
  };

  loadUserOptions = (query, callback) => {
    const params = query ? { Q: query, Page: 1 } : null;
    this.props.getDefaultCompanies(params).then((res) => {
      callback(filterOptions(res, query));
      this.setEditCompanyName(res);
    });
  };

  setEditCompanyName(companies) {
    const { companyName, editUser } = this.state;
    if (editUser && companyName === null) {
      const getCompany = companies.find((company) => company.Id === editUser.CompanyId);
      this.setState({ companyName: getCompany ? getCompany.Name : "" });
    }
  }

  filterOptions = (timeZones, query) => {
    const timeZoneData = timeZones.map((tz) => {
      return {
        label: TIME_ZONE_AREA_NAME[COMPANY_TIME_ZONE[tz]],
        value: COMPANY_TIME_ZONE[tz]
      };
    });

    if (query)
      return timeZoneData.filter((i) => i.label.toLowerCase().includes(query.toLowerCase()));
    else return timeZoneData;
  };

  handleSelectInputChange = (newValue) => {
    const timeZone = newValue.replace(/\W/g, "");
    this.setState({ selectedTimeZone: timeZone });
    return timeZone;
  };

  onChangeSelect = (val) => {
    const { companyPayload } = this.state;
    companyPayload["TimeZone"] = val ? val.value : null;
  };

  onChangeUserSelect = (val) => {
    let userPayload = this.state.userPayload;
    userPayload.CompanyId = val ? val.Id : null;
    this.setState(userPayload);
    this.props.refreshDropdown();
    this.getSegmentsOptions(userPayload.CompanyId)
  };

  isCompanyFieldsValid = () => {
    const { companyPayload, lastEditedPhoneNumberType } = this.state;
    if (!companyPayload.Apps || !(companyPayload.Apps.length)) {
      return false;
    }

    const { Name, TimeZone, PhoneNumber, PhoneType } = companyPayload

    let flag = TimeZone && Name;

    if (this.isCompanySelected(ApplicationTypes.CLUB_CHAT)) {
      if (lastEditedPhoneNumberType === CompanyPhoneTypes.HOSTED_NUMBER) {
        flag = flag && PhoneNumber === this.state.lastValidPhoneNumber.PhoneNumber && validateUSPhoneNumber(PhoneNumber) && PhoneType;
      }
      flag = flag && PhoneType;
    }

    if (this.isCompanySelected(ApplicationTypes.WEB_CHAT)) {

    }

    return flag
    // if (
    //   this.isCompanySelected(ApplicationTypes.RANGE_BOOTH) &&
    //   !this.isCompanySelected(ApplicationTypes.RANGE_CONNECT)
    // ) {
    //   return companyPayload.Name && companyPayload.webName && companyPayload.webUrl && companyPayload.TimeZone;
    // }
    // else {
    //   if (lastEditedPhoneNumberType === CompanyPhoneTypes.HOSTED_NUMBER) {
    //     return companyPayload.Name && companyPayload.webName && companyPayload.webUrl && companyPayload.TimeZone && companyPayload.PhoneNumber === this.state.lastValidPhoneNumber.PhoneNumber && validateUSPhoneNumber(companyPayload.PhoneNumber) && companyPayload.PhoneType;
    //   }
    //   return companyPayload.Name && companyPayload.webName && companyPayload.webUrl && companyPayload.TimeZone && companyPayload.PhoneType;
    // }
  };

  async createCompany(e) {
    e.preventDefault();
    const { editCompany, companyPayload, searching, lastEditedPhoneNumberType } = this.state,
      method = editCompany ? "PUT" : "POST";

    if (!this.isCompanyFieldsValid()) {
      return;
    }

    let payload = { ...companyPayload };
    if (lastEditedPhoneNumberType) {
      payload[`PhoneType`] = parseInt(lastEditedPhoneNumberType)
    }
    if (
      editCompany &&
      this.isCompanySelected(ApplicationTypes.RANGE_BOOTH) &&
      !this.isCompanySelected(ApplicationTypes.RANGE_CONNECT)
    ) {

      payload = {
        Id: payload.Id,
        Name: payload.Name,
        TimeZone: payload.TimeZone,
        FrameRequest: payload.FrameRequest,
        Apps: payload.Apps,
        Segments: payload.Segments,
        webName: payload.webName,
        webUrl: payload.webUrl,
        Address: payload.Address,
        City: payload.City,
        Country: payload.Country,
        Domain: payload.Domain,
        EmailServiceAccess: payload.EmailServiceAccess
      };
    }

    payload.Apps = payload.Apps.map((data) => {
      if (typeof data === "object") {
        return data.Id;
      } else {
        return data;
      }
    });
    if (companyPayload.WebChatAccess) {
      payload.Apps.push(ApplicationTypes.WEB_CHAT);
    }

    payload.Apps = [...new Set(payload.Apps)];

    if (this.state.imageObj) {
      try {
        this.setState({ imageUploading: true });
        let image = await this.props.uploadImage(this.state.imageObj);
        payload["LogoId"] = image.Id;
      } catch (err) {
      } finally {
        this.setState({ imageUploading: false });
      }
    }
    else {
      payload["LogoId"] = this.state.companyPayload.LogoId;
    }
    if (editCompany) {
      this.updateCompanyFormData(payload.Id)
    }
    // if (Boolean(this.isCompanySelected(ApplicationTypes.WEB_CHAT))) {
    // }
    payload.WebChatAccess = this.state.companyPayload.WebChatAccess;
    this.props
      .setCompany(payload, method)
      .then((res) => {
        console.log(res.Id, "DEPENDENCY OF SAVE WEB CHATS")
        this.saveWebChatsChanges(res.Id)
          .then((response) => {
            console.log(response, "response from save web chat")
            if (response) {
              toaster(`Company have been ${editCompany ? "Updated!" : "Created!"}`);
              this.toggleDialog();
            }
            else {
              errorToaster({ Message: "Server timeout" })
            }
          })
          .catch((error) => {
            console.log(error, "error from save web chat")
            errorToaster(error)
          })
        // toaster(`Company have been ${editCompany ? "Updated!" : "Created!"}`);
        // this.toggleDialog();
        this.setState({ imageObj: null });
        if (!editCompany) {
          this.updateCompanyFormData(res.Id)
        }
        if (searching.length !== 0 && method === "POST") {
          this.setState({ searching: "" }, () => {
            this.searchSubmit(null, "search");
          });
        } else {
          this.getCompaniesListing();
        }
      })
      .catch((err) => { });
  }

  getCompaniesListing(page) {
    let { params } = this.state;

    if (page) params = { ...params, Page: page };

    this.props.getCompaniesData(params);
    this.setState({ params });
    searchQuery = false;
  }

  createSegmentOptions = (segments) => {
    return segments.map((data) => ({ label: data.Title, value: data.Id || data.Title, type: data.SegmentType }));
  };

  getSegmentsOptionForOnboard = (segments) => {
    let filteredSegments = segments.filter((eachSegment) => {
      return !eachSegment.FormVisibility && (eachSegment.SegmentType === 1 || !eachSegment.Id);
    })
    return filteredSegments.map((data) => ({ label: data.Title, value: data.Id || data.Title, type: data.SegmentType }));
  }

  getSegmentsOptionValue = (segments) => {
    let filteredSegments = segments.filter((eachSegment) => {
      return eachSegment.FormVisibility && (eachSegment.SegmentType === 1 || !eachSegment.Id);
    })
    return filteredSegments.map((data) => ({ label: data.Title, value: data.Id || data.Title, type: data.SegmentType }));
  }

  handleDropdownOnChange = (values, actionData) => {
    let {
      companyPayload,
      companyPayload: { Segments }
    } = this.state;
    Segments = Segments.map((eachSegment) => {
      let flag = false;
      values.forEach(eachValue => {
        if (eachValue.label === eachSegment.Title) {
          flag = true;
        }
      });
      eachSegment = { ...eachSegment, FormVisibility: flag }
      return eachSegment;
    })
    companyPayload = { ...companyPayload, Segments }
    this.setState({ companyPayload });
  }

  handleSegmentInputChange = (value) => {
    this.setState({ segmentText: value });
  };

  handleSegmentChange = (value) => {
    let flag = false;
    const { editCompany} = this.state;
    console.log(editCompany, " EDIT COMPNAY");
    if(!editCompany){
      flag = true;
    }else{
      value.forEach(eachSegment => {
        if (eachSegment.type === SegmentType.NoSegment) {
          flag = true;
        }
      });
    }
    if (flag) {
      const remainingValues = value.map((val) => val.value);
      const companyPayload = {
        ...this.state.companyPayload,
        Segments: (() => {
          if (!remainingValues.length) {
            return [];
          }
          return this.state.companyPayload.Segments.filter((val) =>
            remainingValues.includes(val.Id || val.Title)
          );
        })()
      };

      this.setState({ companyPayload });
    }

  };

  handleSegmentKeyDown = (e) => {
    const { segmentText } = this.state;
    const {
      companyPayload,
      companyPayload: { Segments }
    } = this.state;
    const isAlreadyExist = !!Segments.find(
      (val) => val.Title.toLowerCase() === segmentText.toLocaleLowerCase()
    );

    switch (e.key) {
      case "Enter":
      case "Tab":
        e.preventDefault();
        if (!segmentText.trim().length || isAlreadyExist) return;

        Segments.push({ Title: segmentText, FormVisibility: false });
        this.setState({ segmentText: "", companyPayload });
        break;
      default:
    }
  };

  toggleDialog() {
    const addCompanyDialog = this.state.addCompanyDialog;
    this.setState({ addCompanyDialog: !addCompanyDialog });
    if (!addCompanyDialog)
      this.setState({
        editCompany: null,
        imageObj: null,
        companyPayload: {
          FrameRequest: FrameRequestTypes.NO,
          OptStatusRequest: OptRequestTypes.NO,
          Segments: CompanyDefaultSegments,
          BrandedFooterRequest: BrandedRequestTypes.NO
        }
      });
  }

  toggleUserDialog(defaultPayload = {}) {
    const addUserDialog = this.state.addUserDialog;
    this.setState({ addUserDialog: !addUserDialog, userUpdatedSegment: [], companySegments: [] });
    if (!addUserDialog) {
      this.setState({
        userPayload: { ...defaultPayload },
        companyName: this.state.companyPayload.Name,
        userSelect: null,
      });
    }
    if (Object.keys(defaultPayload).length > 0) {
      this.getSegmentsOptions(defaultPayload.CompanyId, true)
      this.props.refreshDropdown();
    }
  }

  handleAddNewCompanyUser() {
    const { Id, Apps } = this.state.companyPayload;
    this.toggleUserDialog({
      CompanyId: Id,
      Apps,
    });
  }

  editCompanyDialog(company) {
    this.setState({
      editCompany: company,
      addCompanyDialog: true,
      companyPayload: { ...company },
      lastValidPhoneNumber: {
        PhoneNumber: company.PhoneNumber,
        PhoneType: company.PhoneType
      },
      lastEditedPhoneNumberType: company.PhoneType
    });
    this.getCompanyFormData(company.Id);
  }

  cancelEditCompanyDialog() {
    this.setState({
      editCompany: null,
      addCompanyDialog: false,
      companyPayload: {},
      lastValidPhoneNumber: {},
      lastEditedPhoneNumberType: null,
      allowedSlugs: [],
    })
  }

  deleteCompanyDialogToggle(company) {
    this.setState({
      deleteCompanyDialog: !this.state.deleteCompanyDialog
    });
    deleteCompanyId = company ? company.Id : null;
  }

  actionDeleteCompany() {
    this.props
      .deleteCompany(deleteCompanyId)
      .then((res) => {
        this.getCompaniesListing();
        toaster("The company have been deleted!");
        this.deleteCompanyDialogToggle();
      })
      .catch((err) => errorToaster(err));
  }

  getConnectPhoneStatus = (company, isModal = false) => {
    switch (company.PhoneStatus) {
      case CompanyPhoneStatus.PENDING:
        return <span style={{ color: "#F5A623" }}>Pending</span>;
      case CompanyPhoneStatus.FAILED:
        return (
          <span style={{ color: "#D7273D" }}>
            {!isModal
              ? "Failed"
              : "Failed, clear your input to try again or get a toll-free number below"}
          </span>
        );
      case CompanyPhoneStatus.CONNECTED:
        return !isModal ? (
          company.PhoneNumber
        ) : (
          <span style={{ color: "#3AAF50" }}>Successful</span>
        );
      default:
        return !isModal ? "-" : <span>N/A</span>;
    }
  };

  isCompanySelected = (type, company = null) => {
    const companyPayload = company ? company : this.state.companyPayload;

    if (companyPayload.Apps) {
      return companyPayload.Apps.find((data) => {
        return typeof data === "object" ? type === data.Id : type === data;
      });
    }
    return false;
  };

  shouldPhoneInputEnable = () => {
    const { companyPayload } = this.state;

    if (
      this.isCompanySelected(ApplicationTypes.RANGE_CONNECT) &&
      !companyPayload.PhoneStatus &&
      companyPayload.PhoneType !== CompanyPhoneTypes.TOLL_FREE
    ) {
      return true;
    }
    return false;
  };

  shouldTollButtonEnable = () => {
    const { companyPayload } = this.state;

    if (
      this.isCompanySelected(ApplicationTypes.RANGE_CONNECT) &&
      companyPayload.PhoneType !== CompanyPhoneTypes.LAND_LINE &&
      !companyPayload.PhoneStatus
    ) {
      return true;
    }
    return false;
  };
  shouldHostedButtonEnable = () => {
    const { companyPayload, lastValidPhoneNumber, lastEditedPhoneNumberType } = this.state;
    if (this.isCompanySelected(ApplicationTypes.RANGE_CONNECT) &&
      lastEditedPhoneNumberType === CompanyPhoneTypes.HOSTED_NUMBER &&
      validateUSPhoneNumber(companyPayload.PhoneNumber) &&
      companyPayload.PhoneNumber !== lastValidPhoneNumber.PhoneNumber) {
      return true;
    }
    // else if (
    //     this.isCompanySelected(ApplicationTypes.RANGE_CONNECT) &&
    //     companyPayload.PhoneType !== CompanyPhoneTypes.HOSTED_NUMBER &&
    //     !companyPayload.PhoneStatus
    // ) {
    //     return true;
    // }
    return false;

  };
  handleEnableLandlineClick = () => {
    const { companyPayload } = this.state;
    if (!companyPayload.PhoneNumber || !companyPayload.PhoneNumber.trim().length) {
      return;
    }
    this.setState({ phoneLoading: true });
    this.props
      .orderLandlinePhone(companyPayload.PhoneNumber)
      .then((res) => {
        companyPayload.GatewayOrderId = res.GatewayOrderId;
        companyPayload.PhoneStatus = CompanyPhoneStatus.PENDING;
        companyPayload.PhoneType = CompanyPhoneTypes.LAND_LINE;
        this.setState({ companyPayload });
      })
      .catch((err) => { })
      .finally(() => {
        this.setState({ phoneLoading: false });
      });
  };

  handleGenerateTollFreeClick = () => {
    const { companyPayload } = this.state;
    if (!companyPayload.PhoneNumber || !companyPayload.PhoneNumber.trim().length) {
      return;
    }

    this.setState({ tollNumberLoading: true });
    this.props
      .generateTollFreeNumber(companyPayload.PhoneNumber)
      .then((res) => {
        companyPayload.GatewayOrderId = res.GatewayOrderId;
        companyPayload.PhoneType = CompanyPhoneTypes.TOLL_FREE;
        companyPayload.PhoneStatus = CompanyPhoneStatus.PENDING;
        this.setState({ companyPayload });
      })
      .catch((err) => { })
      .finally(() => {
        this.setState({ tollNumberLoading: false });
      });
  };

  handleHostNumberClick = () => {
    const { companyPayload, lastValidPhoneNumber } = this.state;
    if (!companyPayload.PhoneNumber || !companyPayload.PhoneNumber.trim().length) {
      return;
    }
    this.setState({ hostNumberLoading: true });
    this.props
      .generateHostNumber(companyPayload.PhoneNumber)
      .then((res) => {
        companyPayload.GatewayOrderId = res.GatewayOrderId;
        companyPayload.PhoneType = CompanyPhoneTypes.HOSTED_NUMBER;
        companyPayload.PhoneStatus = CompanyPhoneStatus.CONNECTED;
        lastValidPhoneNumber.PhoneNumber = companyPayload.PhoneNumber;
        lastValidPhoneNumber.PhoneType = CompanyPhoneTypes.HOSTED_NUMBER;
        this.setState({ companyPayload, lastValidPhoneNumber });
      })
      .catch((err) => { })
      .finally(() => {
        this.setState({ hostNumberLoading: false });
      });
  };

  handlePhoneClearClick = () => {
    const { companyPayload } = this.state;

    companyPayload.PhoneStatus = null;
    delete companyPayload.PhoneNumber;
    companyPayload.PhoneType = null;

    this.setState({ companyPayload });
  };

  handleImageSelect = (imageObj) => {
    if (imageObj) {
      this.setState({ imageObj });
    }
  };

  handleCancelClick = () => {
    const { companyPayload } = this.state;
    if ("LogoId" in companyPayload) {
      delete companyPayload.LogoId;
    }
    this.setState({ imageObj: null, companyPayload });
  };

  handleImageError = () => {
    errorToaster({ Message: "Invalid image format" });
  };

  renderDeleteDialog() {
    const { deleteCompanyRequest } = this.props;
    return (
      <Dialog>
        <DialogContent
          icon={dialogContent.icon}
          title={dialogContent.title}
          text={dialogContent.text}
          actionLabel={dialogContent.actionLabel}
          actionClass={dialogContent.actionClass}
          loading={deleteCompanyRequest}
          action={this.actionDeleteCompany.bind(this)}
          cancel={this.deleteCompanyDialogToggle.bind(this)}
        />
      </Dialog>
    );
  }

  handleFieldChanges = (selectedSlug) => {
    let formFields = [...this.state.allowedSlugs];
    if (formFields.includes(selectedSlug)) {
      formFields = formFields.filter((fieldSlug) => selectedSlug !== fieldSlug)
    } else {
      formFields.push(selectedSlug);
    }
    this.setState({
      allowedSlugs: formFields
    })
  }

  getCompanyUsers = (companyId) => {
    const { usersData } = this.state;
    if (!usersData) return [];
    return usersData.filter(u => u.CompanyId === companyId);
  }

  createUser(e) {
    e.preventDefault();
    const { userPayload, userUpdatedSegment } = this.state;

    let payload = { ...userPayload };
    if (payload.Type) payload.Type = +payload.Type;

    if (!payload.Type) {
      errorToaster({ Message: "Please select a role!" });
      return;
    }
    if (!payload.CompanyId && IsCompanyUser(payload.Type)) {
      errorToaster({ Message: `Please select a company!` });
      return null;
    }

    if (IsCompanyUser(payload.Type)) {
      if (!payload.Apps || !payload.Apps.length) {
        errorToaster({ Message: `Please select an application!` });
        return null;
      } else {
        payload.Apps = payload.Apps.map((data) => {
          if (typeof data === "object") {
            return data.Id;
          } else {
            return data;
          }
        });
      }
    }

    this.props.createUser(payload, "POST").then((res) => {
      let updateSegmentPayload = {
        CompanyId: payload.CompanyId,
        UserId: res.Id,
        SegmentId: userUpdatedSegment.map((eachSegment) => eachSegment.Id)
      }
      this.props.updateUserSegments(updateSegmentPayload).then((response) => {
        this.props.getUsersData({}, true).then(res => {
          this.setState({
            usersData: res.Users,
          });
        });
        toaster(`User have been created!`);
        this.toggleUserDialog();
      }).catch((err) => errorToaster(err));
    }).catch((err) => errorToaster(err));
  }

  handleSelectChange = (type) => {
    const { userPayload } = this.state;
    let { value } = type;
    value = Number(value);
    userPayload["Type"] = value;
    this.setState({ userPayload, userSelect: type });
  };

  handleInputChange = (newValue) => {
    const companyName = newValue;
    this.setState({ companyName, userUpdatedSegment: [] });
    return companyName;
  };

  transformSegmentObjectForDropdown = (segmentsArray) => {
    let segmentResponse = segmentsArray.map((eachSegment) => {
      return { ...eachSegment, label: eachSegment.Title, value: eachSegment.Id };
    })
    return segmentResponse;
  }

  getSegmentsOptions = (companyId) => {
    const { getCompanyById } = this.props;
    if (companyId) {
      getCompanyById(companyId).then((response) => {
        this.setState({
          companySegments: this.transformSegmentObjectForDropdown(response.Company.Segments)
        })
      })
    } else {
      this.setState({
        userUpdatedSegment: [],
        companySegments: []
      })
    }
  }

  renderDialog() {
    const { createCompanyRequest } = this.props;
    const {
      editCompany,
      companyPayload,
      selectedTimeZone,
      phoneLoading,
      tollNumberLoading,
      hostNumberLoading,
      segmentText,
      imageUploading
    } = this.state,
      title = editCompany ? "Edit Company" : "Create a New Company",
      requestFramesRadioButtons = [
        {
          id: "membership-yes",
          name: "member",
          label: "Yes",
          value: FrameRequestTypes.YES
        },
        { id: "membership-no", name: "member", label: "No", value: FrameRequestTypes.NO }
      ],
      optStatusRadioButtons = [
        {
          id: "opt-status-yes",
          name: "optStatus",
          label: "Yes",
          value: OptRequestTypes.YES
        },
        { id: "opt-status-no", name: "optStatus", label: "No", value: OptRequestTypes.NO }
      ],
      emailServiceAccessRadioButtons = [
        {
          id: "email-service-yes",
          name: "emailServiceAvaliable",
          label: "Yes",
          value: EmailServiceAccessTypes.YES
        },
        { id: "email-service--no", name: "emailServiceAvaliable", label: "No", value: EmailServiceAccessTypes.NO }
      ],
      mmsStatusRadioButton = [
        {
          id: "mms-status-yes",
          name: "mmsStatus",
          label: "SMS",
          value: MmsRequestTypes.NO
        },
        { id: "mms-status-no", name: "mmsStatus", label: "SMS/MMS", value: MmsRequestTypes.YES }
      ],
      phoneTypeRadioButton = [
        {
          id: CompanyPhoneTypes.TOLL_FREE,
          name: "PhoneType",
          label: "TOLL FREE",
          value: CompanyPhoneTypes.TOLL_FREE
        },
        {
          id: CompanyPhoneTypes.HOSTED_NUMBER,
          name: "PhoneType",
          label: "HOSTED NUMBER",
          value: CompanyPhoneTypes.HOSTED_NUMBER
        },
        {
          id: CompanyPhoneTypes.LAND_LINE,
          name: "PhoneType",
          label: "LANDLINE",
          value: CompanyPhoneTypes.LAND_LINE
        },
      ],
      frameRequest = Number(companyPayload.FrameRequest),
      optStatus = Number(companyPayload.OptStatusRequest),
      emailServiceAvaliable = Number(companyPayload.EmailServiceAvaliable),
      mmsStatus = Number(companyPayload.MMSStatus),
      PhoneType = Number(companyPayload.PhoneType),
      // btnLabel = editCompany ? "Update Company" : "Create Company",
      currentTimeZone = editCompany
        ? TIME_ZONE_AREA_NAME[companyPayload.TimeZone]
        : selectedTimeZone,
      landlinePhone =
        !editCompany || !companyPayload.PhoneType
          ? companyPayload.PhoneNumber || ""
          : companyPayload.PhoneType === CompanyPhoneTypes.LAND_LINE
            ? companyPayload.PhoneNumber
            : companyPayload.PhoneNumber;
    const imagePreview =
      editCompany && companyPayload.Logo
        ? concatBaseMediaUrl(companyPayload.Logo.Path)
        : null;


    const clubChatSelected = Boolean(this.isCompanySelected(ApplicationTypes.CLUB_CHAT));
    const clubBoothSelected = Boolean(this.isCompanySelected(ApplicationTypes.RANGE_BOOTH));

    const isFieldsValid = this.isCompanyFieldsValid();

    return (
      // <DialogModal classes="scrolling m-w-948" closeDialog={this.toggleDialog.bind(this)}>
      <form className="m-a user-dialog form-sec" onSubmit={this.createCompany.bind(this)}>
        <div className="d-flex-row comp-form-header">
          <h2 className="m-b-xs f-45">{title}</h2>
          <div className="flex-center">
            <Button
              classes="cancel-btn"
              label="Cancel"
              clickHandler={this.cancelEditCompanyDialog.bind(this)}
            />
            <Button
              loading={createCompanyRequest || imageUploading || this.state.webChatLoading}
              type="submit"
              classes={isFieldsValid ? "green" : "disabled"}
              disabled={!isFieldsValid}
              label="Save and Close"
            />
          </div>
        </div>
        <div className="d-flex-row form-field-sec">
          <div className="logo-user-sec">
            <fieldset className="field-set logo-col">
              {/* <label className="gray-text bold user-modal-label">
                                Logo
                            </label> */}
              <ImageUploader
                onChange={this.handleImageSelect}
                onCancel={this.handleCancelClick}
                imagePreview={imagePreview}
                onError={this.handleImageError}
              />
            </fieldset>
            {
              editCompany ?
                <>
                  <div className="users-sec">
                    <strong className="gray-text">USERS</strong>
                    <ul>
                      {
                        this.getCompanyUsers(companyPayload.Id).map(u => <li key={u.Id}>{u.Name}</li>)
                      }
                    </ul>
                  </div>
                  <Button
                    label="Add New User"
                    classes="green medium"
                    clickHandler={this.handleAddNewCompanyUser.bind(this)}
                  />
                </> : ''
            }
          </div>
          <div className={"form-col"}>
            <div className="d-flex-row company-row">
              {/* <InputField
                                data-field="Name"
                                value={companyPayload.Name}
                                onChange={this.onChangeHandler}
                                label="COMPANY NAME"
                                placeHolder="Example Country Club"
                                required
                                classes="flex-100 column-5 comp-name-sec"
                            /> */}
              <div className="column-6 comp-name-sec">
                <label className="gray-text bold user-modal-label">COMPANY NAME</label>
                <InputField
                  data-field="Name"
                  value={companyPayload.Name}
                  onChange={this.onChangeHandler}
                  placeHolder="Eg. Club"
                  required
                  classes="flex-70 companyTitle"
                />
                {/* <h2 className="">{companyPayload.Name} <FcCheckmark /></h2> */}
              </div>
              <div className="camp-age column-3">
                <label className="gray-text bold user-modal-label">
                  CAMPAIGN Type
                </label>
                <strong># OF CAMPAIGNS: {companyPayload.CampainCount === undefined || companyPayload.CampainCount === null || companyPayload.CampainCount === -1  ? 0 : companyPayload.CampainCount}</strong>
              </div>
              <div className="camp-age column-3">
                <strong style={{ display: "flex", justifyContent: "space-around" }}>
                  {(companyPayload.BrandStatus === BrandStatus.APPROVE && companyPayload.AgeGated === 1)
                    ? <FcCheckmark />
                    : <FcCancel />
                  }
                  AGE-GATE
                </strong>
              </div>
            </div>
            <div className="d-flex-row company-row">
              <div className="column-4">
                  <label className="gray-text bold user-modal-label">ADDRESS</label>
                  <InputField
                    data-field="Address"
                    value={companyPayload.Address}
                    onChange={this.onChangeHandler}
                    placeHolder="Unnamed Rd. 12"
                  />
              </div>
              <div className="column-4">
                  <label className="gray-text bold user-modal-label">CITY</label>
                  <InputField
                    data-field="City"
                    value={companyPayload.City}
                    onChange={this.onChangeHandler}
                    placeHolder="New York"
                  />
              </div>
              <div className="column-4">
                  <label className="gray-text bold user-modal-label">COUNTRY</label>
                  <InputField
                    data-field="Country"
                    value={companyPayload.Country}
                    onChange={this.onChangeHandler}
                    placeHolder="USA"
                  />
              </div>
            </div>
            <div className="d-flex-row company-row">
              <div className="column-6">
                  <label className="gray-text bold user-modal-label">EMAIL</label>
                  <InputField
                    data-field="Email"
                    value={companyPayload.Email}
                    onChange={this.onChangeHandler}
                    placeHolder="info@example.com"
                  />
              </div>
              <div className="column-6">
                  <label className="gray-text bold user-modal-label">DOMAIN</label>
                  <InputField
                    data-field="Domain"
                    value={companyPayload.Domain}
                    onChange={this.onChangeHandler}
                    placeHolder="example.com"
                  />
              </div>
            </div>
            <div className="flex-row">
              <TypeHead
                label=""
                inputValue={currentTimeZone}
                loadOptions={this.loadOptions}
                placeholder={"Select Time Zone..."}
                onChange={this.onChangeSelect}
                onInputChange={this.handleSelectInputChange}
                isDisabled={!!editCompany}
                classes={
                  editCompany
                    ? "disabled flex-30 m-t-42 filter-gray"
                    : "flex-30 m-t-42 m-b-42"
                }
                styles={customStyles("timezone")}
                showBorder={true}
              />
            </div>
            <label className="gray-text sec-label">
              COMPANY HAS ACCESS TO:
            </label>
            <fieldset className="companies-input__fieldset field-set flex-100">

              <div className="flex-row">

                {/* <CheckBox
                                    label={
                                        <>
                                            Range <strong>CONNECT</strong>
                                        </>
                                    }
                                    checked={
                                        !!this.isCompanySelected(ApplicationTypes.RANGE_CONNECT)
                                    }
                                    changeHandler={(e) =>
                                        this.handleCheckboxChange(e, ApplicationTypes.RANGE_CONNECT)
                                    }
                                /> */}

                <CheckBox
                  label={
                    <>
                      <strong className="sec-checkboc">ClubChat</strong>
                    </>
                  }
                  checked={clubChatSelected}
                  changeHandler={(e) =>
                    this.handleCheckboxChange(e, ApplicationTypes.CLUB_CHAT)
                  }
                />

              </div>
            </fieldset>
            <div className="club-web-chat-sec">
              <div className="d-flex-row companies-input" style={{width:"100%"}}>
                  <CompRadioButton
                    dataField="EmailServiceAvaliable"
                    value={emailServiceAvaliable}
                    onChange={this.onChangeHandler}
                    classes="m-t"
                    label="CAN USE EMAIL CAMPAIGNS?"
                    config={emailServiceAccessRadioButtons}
                  />
              </div>
              <div className="d-flex-row companies-input" style={{width:"100%"}}>
                 <PhoneTypeRadioButton
                 classes="phone-type-radio"
                    dataField="PhoneType"
                    value={PhoneType}
                    onChange={(e) => this.onPhoneTypeChange(e.target.id)}
                    label="Phone Type"
                    config={phoneTypeRadioButton}
                  />
              </div>
              <div className="d-flex-row align-f-end number-row">
                <div className={` column-5 ${!clubChatSelected ? "disable" : ""
                  }`}
                >
                  {Number(companyPayload.PhoneType) === CompanyPhoneTypes.TOLL_FREE && (
                    <ButtonInput
                      label="GENERATE TOLL-FREE NUMBER"
                      data-field="PhoneNumber"
                      data-type={CompanyPhoneTypes.TOLL_FREE}
                      icon={spinnerWhite}
                      value={
                        landlinePhone
                        // companyPayload.PhoneType === CompanyPhoneTypes.TOLL_FREE
                        //     ? companyPayload.PhoneNumber
                        //         ? companyPayload.PhoneNumber
                        //         : companyPayload.PhoneStatus === CompanyPhoneStatus.PENDING
                        //         ? "Pending..."
                        //         : ""
                        //     : ""
                      }
                      inputDisable={true}
                      onChange={this.onChangeHandler}
                      onButtonClick={this.handleGenerateTollFreeClick}
                      buttonDisable={!this.shouldTollButtonEnable()}
                      buttonType={CompanyPhoneTypes.TOLL_FREE}
                      classes="flex-100"
                      buttonClass={tollNumberLoading ? "loading" : ""}
                      // buttonClass={"icon-del"}
                      showCrossBtn={
                        companyPayload.PhoneType === CompanyPhoneTypes.TOLL_FREE &&
                        companyPayload.PhoneStatus === CompanyPhoneStatus.FAILED
                      }
                      onCrossBtnClick={this.handlePhoneClearClick}
                    />
                  )}

                  {Number(companyPayload.PhoneType) === CompanyPhoneTypes.LAND_LINE && (
                    <ButtonInput
                      label="ENABLE LANDLINE PHONE"
                      data-field="PhoneNumber"
                      data-type={CompanyPhoneTypes.LAND_LINE}
                      value={
                        landlinePhone
                        // companyPayload.PhoneType === CompanyPhoneTypes.LAND_LINE
                        // ? companyPayload.PhoneNumber
                        //     ? companyPayload.PhoneNumber
                        //     : companyPayload.PhoneStatus === CompanyPhoneStatus.PENDING
                        //     ? "Pending..."
                        //     : ""
                        // : ""
                      }
                      onChange={this.onChangeHandler}
                      icon={EnableLandline}
                      onButtonClick={this.handleEnableLandlineClick}
                      isLoading={phoneLoading}
                      classes="flex-100"
                      inputDisable={!this.shouldPhoneInputEnable()}
                      buttonDisable={!this.shouldPhoneInputEnable()}
                      buttonType={CompanyPhoneTypes.LAND_LINE}
                      showCrossBtn={
                        companyPayload.PhoneType === CompanyPhoneTypes.LAND_LINE &&
                        companyPayload.PhoneStatus === CompanyPhoneStatus.FAILED
                      }
                      onCrossBtnClick={this.handlePhoneClearClick}
                    />
                  )}

                  {Number(companyPayload.PhoneType) === CompanyPhoneTypes.HOSTED_NUMBER && (
                    <ButtonInput
                      label="HOSTING MESSAGING"
                      data-field="PhoneNumber"
                      data-type={CompanyPhoneTypes.HOSTED_NUMBER}
                      icon={spinnerWhite}
                      value={
                        landlinePhone
                        // companyPayload.PhoneType === CompanyPhoneTypes.HOSTED_NUMBER
                        // ? companyPayload.PhoneNumber
                        //     ? companyPayload.PhoneNumber
                        //     : companyPayload.PhoneStatus === CompanyPhoneStatus.PENDING
                        //     ? "Pending..."
                        //     : ""
                        // : ""
                      }
                      inputDisable={true}
                      onChange={this.onChangeHandler}
                      onButtonClick={this.handleHostNumberClick}
                      buttonType={CompanyPhoneTypes.HOSTED_NUMBER}
                      buttonDisable={!this.shouldHostedButtonEnable()}
                      classes="flex-100"
                      buttonClass={hostNumberLoading ? "loading" : ""}
                      showCrossBtn={
                        companyPayload.PhoneType === CompanyPhoneTypes.HOSTED_NUMBER &&
                        companyPayload.PhoneStatus === CompanyPhoneStatus.FAILED
                      }
                      onCrossBtnClick={this.handlePhoneClearClick}
                    />
                  )}

                </div>
                {/* <div className={` column-3 ${!clubChatSelected ? "disable" : ""
                  }`}
                >
                 
                </div>
                <div className={` column-3 ${!clubChatSelected ? "disable" : ""
                  }`}
                >
                 
                </div> */}
                {/* <div className={` column-3 ${!clubChatSelected ? "disable" : ""
                  }`}
                >
                  <TypeHead
                    label=""
                    inputValue={currentTimeZone}
                    loadOptions={this.loadOptions}
                    placeholder={"Select Time Zone..."}
                    onChange={this.onChangeSelect}
                    onInputChange={this.handleSelectInputChange}
                    isDisabled={!!editCompany}
                    classes={
                      editCompany
                        ? "disabled flex-95 m-t-42 filter-gray"
                        : "flex-95 m-t-42"
                    }
                    styles={customStyles}
                    showBorder={true}
                  />
                </div> */}
                {companyPayload.PhoneType === CompanyPhoneTypes.TOLL_FREE &&
                  companyPayload.PhoneStatus === CompanyPhoneStatus.FAILED && (
                    <div className="phone-status__text">
                      <span style={{ color: "#D7273D" }}>
                        Failed, clear your input to try again or get a land-line
                        number above.
                      </span>
                    </div>
                  )}
              </div>
              <div className="d-flex-row align-f-end radio-checkbox-row">
                <div className={`companies-input ${!clubChatSelected ? "disable" : ""
                  }`}
                >
                  {/* <RadioButton
                                        dataField="OptStatusRequest"
                                        value={optStatus}
                                        onChange={this.onChangeHandler}
                                        classes="m-t"
                                        label="OPT-IN STATUS?"
                                        config={optStatusRadioButtons}
                                    /> */}
                  <CompRadioButton
                    dataField="OptStatusRequest"
                    value={optStatus}
                    onChange={this.onChangeHandler}
                    classes="m-t"
                    label="OPT-IN STATUS?"
                    config={optStatusRadioButtons}
                  />
                </div>
                <div className={`companies-input act-type-btn flex-col ${!clubChatSelected ? "disable" : ""
                  }`}
                >
                  {/* <RadioButton
                                            dataField="MMSStatus"
                                            value={mmsStatus}
                                            onChange={this.onChangeHandler} y
                                            label="ACCOUNT SENDING TYPE"
                                            config={mmsStatusRadioButton}

                                        /> */}
                  <CompRadioButton
                    dataField="MMSStatus"
                    value={mmsStatus}
                    onChange={this.onChangeHandler}
                    label="ACCOUNT SENDING TYPE"
                    config={mmsStatusRadioButton}
                  />
                </div>

                <div className={` companies-input phone-status ${!clubChatSelected ? "disable" : ""
                  }`}
                >

                  <fieldset className="companies-input__fieldset field-set flex-100">
                    <p className="gray-text bold user-modal-label">
                      Onboard form fields
                    </p>
                    <div className="companies-input__checkbox" style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                      <div className={"check-row"}>
                        <CompCheckBox
                          label={
                            <>
                              Member #
                            </>
                          }
                          checked={!!this.state.allowedSlugs.includes(FormFieldSlug.memberNo)}
                          changeHandler={(e) =>
                            this.handleFieldChanges(FormFieldSlug.memberNo)
                          }
                          classes="m-r"
                        />

                        {/* <CheckBox
                                                        label={
                                                            <>
                                                                MEMBER #
                                                            </>
                                                        }
                                                        checked={!!this.state.allowedSlugs.includes(FormFieldSlug.memberNo)}
                                                        changeHandler={(e) =>
                                                            this.handleFieldChanges(FormFieldSlug.memberNo)
                                                        }
                                                        classes="m-r"
                                                    /> */}
                        <CompCheckBox
                          label={
                            <>
                              Email
                            </>
                          }
                          checked={!!this.state.allowedSlugs.includes(FormFieldSlug.email)}
                          changeHandler={(e) =>
                            this.handleFieldChanges(FormFieldSlug.email)
                          }
                          classes="m-r"
                        />
                        <CompCheckBox
                          label={
                            <>
                              Gender
                            </>
                          }
                          checked={!!this.state.allowedSlugs.includes(FormFieldSlug.gender)}
                          changeHandler={(e) =>
                            this.handleFieldChanges(FormFieldSlug.gender)
                          }
                          classes="m-r"
                        />
                        {/* <CheckBox
                                                        label={
                                                            <>
                                                                GENDER
                                                            </>
                                                        }
                                                        checked={!!this.state.allowedSlugs.includes(FormFieldSlug.gender)}
                                                        changeHandler={(e) =>
                                                            this.handleFieldChanges(FormFieldSlug.gender)
                                                        }
                                                        classes="m-r"
                                                    /> */}


                        {/* <CheckBox
                                                        label={
                                                            <>
                                                                EMAIL
                                                            </>
                                                        }
                                                        checked={!!this.state.allowedSlugs.includes(FormFieldSlug.email)}
                                                        changeHandler={(e) =>
                                                            this.handleFieldChanges(FormFieldSlug.email)
                                                        }
                                                        classes="m-r"
                                                    /> */}
                      </div>
                      {/* <div className={"flex-col flex-sa"}>
                                                    

                                                    <CheckBox
                                                        label={
                                                            <>
                                                                DATE OF BIRTH
                                                            </>
                                                        }
                                                        checked={!!this.state.allowedSlugs.includes(FormFieldSlug.birthday)}
                                                        changeHandler={(e) =>
                                                            this.handleFieldChanges(FormFieldSlug.birthday)
                                                        }
                                                        classes="m-r"
                                                    />
                                                </div> */}
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="d-flex-row segment-sec" style={{ width: "85%" }}>
                <div className={` companies-input first ${!clubChatSelected ? "disable" : ""
                  }`}
                >
                  <fieldset
                    className="companies-input__fieldset field-set flex-100 segment-col"
                    id="segment"
                  >
                    <label className="gray-text bold">SEGMENTS</label>
                    <CreatableSelect
                      inputId="segments"
                      components={components}
                      isClearable
                      isMulti
                      menuIsOpen={false}
                      placeholder="Type and press enter..."
                      styles={customStyles("segments")}
                      inputValue={segmentText}
                      onInputChange={this.handleSegmentInputChange}
                      value={this.createSegmentOptions(companyPayload.Segments)}
                      onKeyDown={this.handleSegmentKeyDown}
                      onChange={this.handleSegmentChange}
                    />
                  </fieldset>
                </div>
                <div className={` companies-input first ${!clubChatSelected ? "disable" : ""
                  }`}
                >
                  <fieldset
                    className="companies-input__fieldset field-set flex-100 segment-col"
                    id="segment"
                  >
                    <DropdownSelector
                      inputValue={"segments"}
                      loadOptions={this.getSegmentsOptionForOnboard(companyPayload.Segments)}
                      placeholder={"Select"}
                      onChange={this.handleDropdownOnChange}
                      fetching={false}
                      value={this.getSegmentsOptionValue(companyPayload.Segments)}
                      classes={"flex-100"}
                      styles={customStyles("segments2")}
                      label="SEGMENTS ON THE ONBOARD FORM"
                      showBorder={true}
                    />
                  </fieldset>
                </div>
              </div>
            </div>

            <WebChatSection
              companyPayload={companyPayload}
              webChatSelected={this.state.companyPayload.WebChatAccess ? true : false}
              handleWebChatAccess={this.handleWebChatAccess}
              onChangeHandler={this.onChangeHandler}
              registerSaveChanges={(fn) => { this.saveWebChatsChanges = fn }}
              getWebChatsFetching={(webChatLoading) => {
                this.setState({ webChatLoading: webChatLoading })
              }}
            />

            <div className="d-flex-row flex-col">
              <div className="column-12">
                <CheckBox
                  label={
                    <>
                      <strong className="sec-checkboc">ClubBooth</strong>
                    </>
                  }
                  checked={clubBoothSelected}
                  changeHandler={(e) =>
                    this.handleCheckboxChange(e, ApplicationTypes.RANGE_BOOTH)
                  }
                  classes="m-r-xs"
                />
              </div>
              <div className="club-web-chat-sec clubbooth-sec">
                <div className="column-12">
                  <div className={`companies-input ${!clubBoothSelected ? "disable" : ""
                    }`}
                  >
                    {/* <RadioButton
                                        dataField="FrameRequest"
                                        value={frameRequest}
                                        onChange={this.onChangeHandler}
                                        classes="m-t"
                                        label="CAN THIS COMPANY REQUEST CUSTOM FRAMES?"
                                        config={requestFramesRadioButtons}
                                    /> */}
                    <CompRadioButton
                      dataField="FrameRequest"
                      value={frameRequest}
                      onChange={this.onChangeHandler}
                      classes="m-t"
                      label="CAN THIS COMPANY REQUEST CUSTOM FRAMES?"
                      config={requestFramesRadioButtons}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="d-flex-row">
                    <div className={""}>
                        
                        <div className="phone-status__text">
                            Status:{" "}
                            {companyPayload.PhoneType === CompanyPhoneTypes.LAND_LINE
                                ? this.getConnectPhoneStatus(companyPayload, true)
                                : "N/A"}
                        </div>
                    </div>
                </div> */}
          </div>

        </div>
      </form>
      // </DialogModal>
    );
  }

  renderUserDialog() {
    const { createUserRequest } = this.props;
    const { userPayload, companyName } = this.state;
    const companyLabel = companyName || "";
    userPayload.ApiKey = userPayload.ApiKey || "";
    return (
      <DialogModal classes="scrolling m-w-948" closeDialog={this.toggleUserDialog.bind(this)}>
        <form className="m-a user-dialog" onSubmit={this.createUser.bind(this)}>
          <h2 className="m-b-sm f-45">Create a New User</h2>
          <div className="flex-sa">
            <InputField
              data-field="Name"
              value={userPayload.Name}
              onChange={this.onUserChangeHandler}
              label="FULL NAME"
              placeHolder="Full Name"
              required
              classes="flex-45"
            />
            <fieldset
              className={`type-head flex-45 m-t-42`}
            >
              <div className="select-box__border">
                <Select
                  value={this.state.userSelect}
                  onChange={this.handleSelectChange.bind(this)}
                  options={optionsForCreate}
                  inputId="userSelect"
                  styles={customStyles()}
                  placeholder="Assign Role..."
                />
              </div>
            </fieldset>
          </div>
          <div className="flex-sa">
            <InputField
              data-field="Email"
              value={userPayload.Email}
              onChange={this.onUserChangeHandler}
              type="email"
              label="EMAIL ADDRESS"
              placeHolder="Email Address"
              required
              classes="flex-45"
            />
            <InputField
              data-field="Password"
              value={userPayload.Password}
              onChange={this.onUserChangeHandler}
              type="password"
              label="PASSWORD"
              placeHolder="******"
              required={true}
              classes="flex-45"
            />
          </div>
          <div className="flex-sa">
            <TypeHead
              inputValue={companyLabel}
              loadOptions={this.loadUserOptions}
              placeholder={companyLabel || "SEARCH COMPANIES"}
              onChange={this.onChangeUserSelect}
              onInputChange={this.handleInputChange.bind(this)}
              isDisabled={userPayload.Type === USER_TYPE.ADMIN}
              classes={
                userPayload.Type === USER_TYPE.ADMIN
                  ? "disabled flex-45"
                  : "flex-45"
              }
              styles={customStyles()}
              label="Company Name"
              showBorder={true}
            />
            <DropdownSelector
              inputValue={companyLabel}
              loadOptions={this.state.companySegments}
              placeholder={"Select"}
              onChange={(values) => this.setState({ userUpdatedSegment: values })}
              fetching={this.props.fetchingCompanyData}
              value={this.state.userUpdatedSegment}
              classes={"flex-45"}
              styles={customStyles()}
              label="Can Send Message To"
              showBorder={true}
            />
          </div>

          <div className="flex-center">
            <fieldset className="field-set flex-45 users-checkboxes chat-checkboxes">
              <label className="gray-text bold user-modal-label">Has access to</label>
              <div className={userPayload.Type === USER_TYPE.ADMIN ? "disable" : ""}>
                <CheckBox
                  label={
                    <>
                      CLUB<strong>BOOTH</strong>
                    </>
                  }
                  checked={!!this.isCompanySelected(ApplicationTypes.RANGE_BOOTH, userPayload)}
                  changeHandler={(e) => this.handleUserCheckboxChange(e, ApplicationTypes.RANGE_BOOTH)}
                  classes="m-r-xs"
                />
                <CheckBox
                  label={
                    <>
                      CLUB<strong>CHAT</strong>
                    </>
                  }
                  checked={
                    !!this.isCompanySelected(ApplicationTypes.CLUB_CHAT, userPayload)
                  }
                  changeHandler={(e) =>
                    this.handleUserCheckboxChange(e, ApplicationTypes.CLUB_CHAT)
                  }
                  classes="m-r-xs"
                />

                <CheckBox
                  label={
                    <>
                      WEB<strong>CHAT</strong>
                    </>
                  }
                  checked={
                    !!this.isCompanySelected(ApplicationTypes.WEB_CHAT, userPayload)
                  }
                  changeHandler={(e) =>
                    this.handleUserCheckboxChange(e, ApplicationTypes.WEB_CHAT)
                  }
                />
              </div>
            </fieldset>
          </div>
          <div className="flex-center m-t">
            <Button
              loading={createUserRequest}
              type="submit"
              classes="green"
              label={"Create User"}
            />
          </div>
        </form>
      </DialogModal>
    );
  }

  renderTableData() {
    const { fetching, companies } = this.props;
    return !fetching && !companies.length ? (
      <tr>
        <td colSpan={6} className="no-data bold secondary-text">
          {searchQuery ? "Sorry, No data match for this criteria" : "No data yet"}
        </td>
      </tr>
    ) : (
      companies.map((company, i) => (
        <tr key={i}>
          <td>{company.Name}</td>
          <td>
            {this.isCompanySelected(ApplicationTypes.RANGE_BOOTH, company)
              ? "Yes"
              : "No"}
          </td>
          <td>
            {this.isCompanySelected(ApplicationTypes.RANGE_CONNECT, company)
              ? "Yes"
              : "No"}
          </td>
          <td>{this.getConnectPhoneStatus(company)}</td>
          <td>{company.FrameRequest === FrameRequestTypes.YES ? "Yes" : "No"}</td>
          <td>{company.OptStatusRequest === OptRequestTypes.YES ? "Yes" : "No"}</td>
          <td className="right">
            <DropdownButton>

              {/* <Link to="/dashboard/editcompanynew">
                                <button type="button">
                                    Edit Company New
                                </button>
                            </Link> */}
              <button onClick={this.editCompanyDialog.bind(this, company)}>
                Edit Company
              </button>
              <button
                className="danger-text"
                onClick={this.deleteCompanyDialogToggle.bind(this, company)}
              >
                Delete Company
              </button>
            </DropdownButton>
          </td>
        </tr>
      ))
    );
  }

  onChangeSearch(e) {
    this.setState({ searching: e.target.value });
    if (e.target.value.length === 0) {
      this.setState({ searching: "" }, () => {
        this.searchSubmit(null, "search");
      });
    }
  }

  searchSubmit(e, type = "form") {
    type === "form" && e.preventDefault();
    let { searching } = this.state,
      params = { Page: 1 };
    if (searchingText === searching) return false;
    if (searching) params.Q = searching;
    this.props.getCompaniesData(params);
    this.setState({ params });
    searchingText = searching;
    searchQuery = !!searching;
  }

  renderTable() {
    const { fetching, totalCompanies } = this.props;
    const { params } = this.state;
    return (
      <div className="relative">
        <Table classes="no-scroll">
          <HeadTr>
            <th>COMPANY NAME</th>
            <th>RANGE BOOTH?</th>
            <th>RANGE CONNECT?</th>
            <th>CONNECT PHONE #</th>
            <th>CAN REQUEST FRAMES?</th>
            <th>CAN REQUEST OPTSTATUS?</th>
            <th>&nbsp;</th>
          </HeadTr>
          <tbody>{this.renderTableData()}</tbody>
        </Table>
        {fetching ? <LayoutLoading /> : null}
        {totalCompanies > rowLimit ? (
          <Pagination
            total={totalCompanies}
            active={params.Page}
            clickHandler={this.getCompaniesListing.bind(this)}
          />
        ) : null}
      </div>
    );
  }

  renderCompaniesListing() {
    const { fetching, companies } = this.props;
    return fetching && !companies.length ? (
      <div className="flex-center p-y-xlg">
        <Spinner classes="green large" />
      </div>
    ) : (
      this.renderTable()
    );
  }

  render() {
    const { addCompanyDialog, deleteCompanyDialog, addUserDialog } = this.state;
    return (
      <section>
        {!addCompanyDialog ? <><div className="flex-end-center m-b">
          <Button
            icon={addWhite}
            label="Create Company"
            classes="green medium"
            clickHandler={this.toggleDialog.bind(this)}
          />
        </div>
          <div className="white-box">
            <div className="table-search">
              <SearchField
                onSubmit={this.searchSubmit.bind(this)}
                onChange={this.onChangeSearch.bind(this)}
                value={this.state.searching}
                placeHolder="Search Companies... "
              />
            </div>
            {this.renderCompaniesListing()}
          </div> </> : null}
        {addCompanyDialog ? this.renderDialog() : null}
        {addUserDialog ? this.renderUserDialog() : null}
        {deleteCompanyDialog ? this.renderDeleteDialog() : null}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  createUserRequest: state.users.createUserRequest,
  fetching: state.companies.fetching,
  createCompanyRequest: state.companies.createCompanyRequest,
  deleteCompanyRequest: state.companies.deleteCompanyRequest,
  fetchingCompanyData: state.companies.fetchingCompanyData,
  companies: state.companies.data,
  totalCompanies: state.companies.totalCompanies
});

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (...params) => dispatch(createUser(...params)),
    updateUserSegments: (...params) => dispatch(updateUserSegments(...params)),
    getUsersData: (...params) => dispatch(getUsersData(...params)),
    setTempAddUserPayload: (params) => dispatch(setTempAddUserPayload(params)),
    getCompaniesData: (params) => dispatch(getCompaniesData(params)),
    setCompany: (data, method) => dispatch(setCompany(data, method)),
    deleteCompany: (id) => dispatch(deleteCompany(id)),
    clearCompaniesDataListing: () => dispatch(clearCompaniesDataListing()),
    getComapnyTimeZones: () => dispatch(getComapnyTimeZones()),
    orderLandlinePhone: (number) => dispatch(orderLandlinePhone(number)),
    generateTollFreeNumber: (number) => dispatch(generateTollFreeNumber(number)),
    generateHostNumber: (number) => dispatch(generateHostNumber(number)),
    getCompanyById: (number) => dispatch(getCompanyById(number)),
    getDefaultCompanies: (params) => dispatch(getDefaultCompanies(params)),
    refreshDropdown: () => dispatch(refreshDropdown()),

    uploadImage: (data) => dispatch(uploadImage(data)),
    getCompanyFormFields: (companyId, formId) => dispatch(getCompanyFormFields(companyId, formId)),
    updateCompanyFormFields: (companyId, formId, slug) => dispatch(updateCompanyFormFields(companyId, formId, slug))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Companies));
