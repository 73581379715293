import React, { Component } from "react";
import { closeGray } from "../../assets/svg-icon";
import Dialog from "./Dialog";
import { IconButton } from "../Button";

import "./Dialog.scss";

class DialogModal extends Component {
  render() {
    const { closeDialog, classes = "", children } = this.props;

    return (
      <Dialog classes={`dialog-modal ${classes}`}>
        <IconButton
          clickHandler={closeDialog}
          classes="white close-dialog"
          icon={closeGray}
        />
        {/*fancy-scroll*/}
        <div className="dialog-modal__container flex-100">{children}</div>
      </Dialog>
    );
  }
}

export default DialogModal;
