import React, { Component } from "react";

import  SidebarList from  './SidebarList';
import {usersWhite, usersBlue, companiesWhite, companiesBlue} from "../../assets/svg-icon";


class AdminMenus extends Component {


    render() {
        return (
            <div>
                <nav className="m-t-md">
                    <ul>
                        <SidebarList to="/dashboard/users" icon={usersWhite} hoverIcon={usersBlue}  name="Users"/>
                        <SidebarList to="/dashboard/companies" icon={companiesWhite} hoverIcon={companiesBlue} name="Companies"/>
                    </ul>
                </nav>
            </div>
        );
    }
}


export default AdminMenus;
