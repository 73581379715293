import { AUTH_FAIL } from "../redux/actions";
import { store } from "../redux/store";

import { errorToaster } from "../utils/loggerUtils";

export const handleFetchError = async (res) => {
    if (res.status >= 400 && res.status < 600) {
        if (store.getState().auth.isAuthenticated && res.status === 401) {
            errorToaster({ Message: "Your Session has been Expired!" });
            localStorage.clear();
            store.dispatch({ type: AUTH_FAIL });
            return;
        }
        const response = await res.json();
        let exp = {
            Message: response.Message,
            Status: res.status,
            ...(response.Errors && { Errors: response.Errors })
        };
        // errorToaster(response.Message)
        throw exp;
    }
    return res.json();
};
