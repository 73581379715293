import React from "react";

import "./ImageUploader.css";

const ALLOWED_TYPES = ["jpg", "jpeg", "png", "gif"];

class ImageUploader extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            dragging: false,
            imagePreview: null
        };
        this.dragCounter = 0;
    }

    componentDidMount() {
        if (this.props.imagePreview) {
            this.setState({ imagePreview: this.props.imagePreview });
        }
    }

    handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ dragging: true });
        }
    };

    handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter > 0) {
            return;
        }
        this.setState({ dragging: false });
    };

    handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    showImagePreview = (file) => {
        try {
            let reader = new FileReader();
            this.setState({ isLoading: true });
            reader.onload = (e) => {
                let selectedImage = e.target.result;
                this.setState({ imagePreview: selectedImage, isLoading: false });
            };
            reader.readAsDataURL(file);
        } catch (err) {
            this.setState({ isLoading: false });
        }
    };

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ dragging: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            let imageObj = e.dataTransfer.files[0];
            this.handleImageUpload(imageObj);
            e.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    };

    handleImageUpload = (imageObj) => {
        const fileType = imageObj.type.split("/").pop();
        if (ALLOWED_TYPES.includes(fileType)) {
            this.showImagePreview(imageObj);
            this.props.onChange(imageObj);
        } else {
            this.props.onError();
        }
    };

    handleCancelClick = () => {
        this.setState({ imagePreview: null });
        this.props.onCancel();
    };

    render() {
        const { imagePreview, isLoading, dragging } = this.state;
        const style = imagePreview
            ? {
                  backgroundImage: `url(${imagePreview})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover"
              }
            : {};

        return (
            <div className="file-uploader">
                <div className={`file-uploader-main ${dragging ? "overlay" : ""}`} style={style}>
                    {!imagePreview && !isLoading && (
                        <React.Fragment>
                            <div
                                className="file-uploader-input"
                                onDragEnter={this.handleDragEnter}
                                onDragLeave={this.handleDragLeave}
                                onDragOver={this.handleDragOver}
                                onDrop={this.handleDrop}
                            >
                                <input
                                    type="file"
                                    onChange={(e) => this.handleImageUpload(e.target.files[0])}
                                    accept="image/gif, image/jpeg, image/png"
                                />
                            </div>

                            {!dragging ? (
                                <div className="file-uploader-text-container">
                                    <div className="file-uploader-text">
                                        Click here or drop the file to upload
                                    </div>
                                </div>
                            ) : (
                                <div className="file-uploader-overlay">
                                    Drop here <i className="fa fa-cloud-upload"></i>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                    {imagePreview && (
                        <div className="file-uploader-cancel">
                            <div onClick={this.handleCancelClick}>
                                <i className="fa fa-times-circle"></i>
                            </div>
                        </div>
                    )}
                    {/* {isLoading && <ProgressLoader />} */}
                </div>
            </div>
        );
    }
}

export default ImageUploader;
