import { BrandedRequestTypes, ApplicationTypes } from "../../../config/constants";
import React, { useEffect, useState } from 'react';
import {
  InputField,
  CompRadioButton,
  CheckBox,
} from "../../../components/Input";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsPlusCircle } from 'react-icons/bs'
import { MdDeleteForever } from 'react-icons/md'
import { connect } from "react-redux";
import { getWebchatsById, updateWebchats } from '../../../redux/actions';
import { Spinner } from "../../../components/Loading";
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getScript } from "../../../utils/commonUtils";

const BrandedFooterRadioButtons = (name) => ([
  {
    name,
    id: "branded-yes" + name,
    label: "Yes",
    value: BrandedRequestTypes.YES
  },
  { name, id: "branded-no" + name, label: "No", value: BrandedRequestTypes.NO }
]);

const WebChatSection = ({ registerSaveChanges, companyPayload, webChatSelected, handleWebChatAccess, getWebchatsById, updateWebchats, webChatsFetching }) => {

  const [webChats, setWebChats] = useState([]);
  const [deletedWebsites, setDeletedWebsites] = useState([]);

  const userId = useSelector(state => state.auth.user.Id);

  const saveChanges = (Id) => {
    let data = {
      WebChatWigets: webChats,
      WebChatDeleteIds: deletedWebsites
    };
    if (Id) {
      let updatedData = webChats.map(web => {
        return {
          ...web,
          CompanyId: Id
        }
      })
      data.WebChatWigets = updatedData
    }

    console.warn("Updating WebChat Websites", data);
    return updateWebchats(data)
  }

  useEffect(() => {
    // get web chats by company id
    if (companyPayload.Id) {
      getWebchatsById(companyPayload.Id).then(res => {
        console.warn(res);
        setWebChats(res.data);
      });
    }
  }, [setWebChats, getWebchatsById, companyPayload.Id]);

  const onChangeHandler = (i, e) => {
    const { value, dataset: { field } } = e.target;
    setWebChats(webChats => {
      const data = [...webChats];
      data[i][field] = value;
      return data
    });
  }

  const removeWebsite = (i) => {
    const Id = webChats[i].Id;
    setWebChats(webChats => webChats.filter((v, _i) => _i !== i));
    if (Id) setDeletedWebsites(d => ([...d, Id]))
  }

  const addNewWebsite = () => {
    const newEntry = {
      BrandedFooter: 1,
      WebsiteDomain: "",
      WebsiteName: "",
      ApiKey: uuidv4(),
      CompanyId: companyPayload.Id,
      CreatedBy: userId,

    }
    setWebChats(webChats => ([...webChats, newEntry]));
  };

  function renderWebSiteEntry(v = { Id: 1 }, index) {
    console.log(webChatsFetching, "WEBCHATS FETCHING")
    const { Id, BrandedFooter, WebsiteDomain, WebsiteName, ApiKey, copied } = v;
    return <div className="webchat-item" key={index}>
      <div className="column-12 webchat-checkbox">

        <div className={`companies-input ${!webChatSelected || webChatsFetching ? "disable" : ""}`}
        >
          <CompRadioButton
            dataField={"BrandedFooter"}
            value={BrandedFooter}
            onChange={e => onChangeHandler(index, e)}
            classes="m-t"
            label="BRANDED FOOTER?"
            config={BrandedFooterRadioButtons(Id)} />
        </div>
      </div>
      <div className="d-flex-row web-chat-sec">
        <div className={`column-4 ${!webChatSelected || webChatsFetching ? "disable" : ""}`}
        >
          <InputField
            data-field="WebsiteName"
            value={WebsiteName}
            onChange={e => onChangeHandler(index, e)}
            label="WEBSITE NAME"
            placeHolder="Add Website Name"
            required
            disabled={!webChatSelected || webChatsFetching}
            classes="flex-100" />
        </div>
        <div className={`column-4 ${!webChatSelected || webChatsFetching ? "disable" : ""}`}
        >
          <InputField
            data-field="WebsiteDomain"
            value={WebsiteDomain}
            onChange={e => onChangeHandler(index, e)}
            label="WEBSITE URL"
            placeHolder="Add Website URL"
            disabled={!webChatSelected || webChatsFetching}
            required
            type="url"
            classes="flex-100" />
        </div>
        <div
          style={{
            marginBottom: 3,
            opacity: !webChatSelected || webChatsFetching ? 0.5 : 1,
          }} className={`column-2 ${!webChatSelected || webChatsFetching ? "disable" : ""}`}
        >
          <CopyToClipboard text={getScript(ApiKey)}
            onCopy={() => {
              const copiedData = val => ({ target: { dataset: { field: "copied" }, value: val } })
              onChangeHandler(index, copiedData(true));
              setTimeout(() => onChangeHandler(index, copiedData(false)), 3000);
            }}>
            <a href="/" onClick={e => e.preventDefault()}>
              {copied ? <span className="copied-url">Copied</span> : <span className="copy-code">Copy</span>}
            </a>
          </CopyToClipboard>
        </div>
        <div
          className={`column-2 deleteWebsiteBtn ${!webChatSelected || webChatsFetching ? "disable" : ""}`}
          style={{
            marginLeft: -20,
            cursor: !webChatSelected || webChatsFetching ? "default" : "pointer",
            opacity: !webChatSelected || webChatsFetching ? 0.5 : 1,
          }}
          onClick={() => webChatSelected && removeWebsite(index)}
        >
          <MdDeleteForever color="maroon" size={28} />
        </div>
      </div>
    </div>;
  }

  registerSaveChanges(saveChanges);

  return (
    <div className="d-flex-row flex-col webChatSection">
      {webChatsFetching ? <Spinner classes="green small" /> : ''}
      <div className="column-12">
        <CheckBox
          label={
            <>
              <strong className="sec-checkboc">WebChat</strong>
            </>
          }
          checked={webChatSelected}
          changeHandler={(e) =>
            handleWebChatAccess(e, ApplicationTypes.WEB_CHAT)
          }
          classes="m-r-xs"
        />
      </div>
      <div className="club-web-chat-sec">
        {webChats.map(renderWebSiteEntry)}
        {webChats.length === 0 && !webChatsFetching ? addNewWebsite() : ''}
      </div>
      <div className={`column-12 webchat-add ${!webChatSelected || webChatsFetching ? "disable" : ""
        }`}
      >
        <button className="add-websie-btn" type="button" onClick={addNewWebsite}><BsPlusCircle />Add Website</button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  webChatsFetching: state.companies.webChatsFetching,
});

const mapDispatchToProps = {
  getWebchatsById,
  updateWebchats,
};


export default connect(mapStateToProps, mapDispatchToProps)(WebChatSection);


