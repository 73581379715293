import React from "react";

import { Spinner } from "../../Loading";
import { clearCircle } from "../../../assets/svg-icon";

const ButtonInput = (props) => {
    const {
        label,
        classes = "",
        buttonClass = "",
        value,
        onChange,
        icon,
        onButtonClick,
        isLoading = false,
        inputDisable,
        buttonDisable,
        showCrossBtn = false,
        onCrossBtnClick,
        buttonType,
        ...rest
    } = props;
    const handleInputChange = (event) => {
        onChange(event, buttonType)
    }
    return (
        <fieldset className={`field-set ${classes} ${inputDisable ? "gray" : ""}`}>
            <label className="gray-text bold">{label}</label>
            <div className="relative">
                <input type="text" value={value} onChange={buttonType ? handleInputChange : onChange} {...rest} />
                {showCrossBtn && (
                    <div className="companies-input__cross flex" onClick={onCrossBtnClick}>
                        <img src={clearCircle} alt="sms icon" />
                    </div>
                )}
                <div
                    className={`companies-input__icon-container ${buttonClass} ${buttonDisable ? "gray" : ""
                        }`}
                    onClick={onButtonClick}
                >
                    {!isLoading ? <img src={icon} alt="sms icon" /> : <Spinner classes="small" />}
                </div>
            </div>
        </fieldset>
    );
};

export default ButtonInput;
