import { fetchUtil } from "../../utils/fetchUtils";
import { errorToaster } from "../../utils/loggerUtils";
import { AuthenticationCodes } from "../../config/constants";
import { persistor } from "../store";
import { changeImpersonateModalView } from "../actions/usersActions";

// Login actions
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_CODE = "SET_CODE";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_FAIL = "ME_FAIL";
export const CHANGE_VIEW = "CHANGE_VIEW";
export const SIGN_OUT = "SIGN_OUT";
export const FETCHING = "FETCHING";
export const AUTH_FAIL = "AUTH_FAIL";

const saveToLocalStorage = (res) => {
    if (
        res.Code !== AuthenticationCodes.ALREADY_REGISTERED &&
        res.Code !== AuthenticationCodes.INVALID_TOKEN
    ) {
        localStorage.RAToken = res.Token;
        localStorage.RACode = res.Code;
    }
};

export const setCode = (code) => ({
    type: SET_CODE,
    payload: parseInt(code, 10)
});

export const login = (email, password, appId = null) => (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    dispatch({ type: FETCHING, payload: true });
    const body = JSON.stringify({
        Email: email,
        Password: password,
        ...(appId && { AppId: appId })
    });
    fetchUtil({
        url: "/login",
        method: "POST",
        toastErrorMessage:true,
        body
    })
        .then((res) => {
            if (res.RedirectUrl) {
                let redirectUrl = `${res.RedirectUrl}/verify/${res.Token}`;
                dispatch({ type: FETCHING, payload: false });
                window.location.replace(redirectUrl);
            } else if (res.Apps) {
                dispatch(changeImpersonateModalView(true));
                dispatch({ type: FETCHING, payload: false });
            } else {
                saveToLocalStorage(res);

                if (res.Code === AuthenticationCodes.USER_LOGIN_SUCCESS) {
                    dispatch(getMe());
                }
            }
        })
        .catch((err) => {
            errorToaster({ Message: err.message });
            dispatch({ type: LOGIN_FAIL, payload: err.Message });
            dispatch({ type: FETCHING, payload: false });
        });
};

export const getMe = () => (dispatch) => {
    if (
        localStorage.RAToken !== undefined &&
        parseInt(localStorage.RACode, 10) === AuthenticationCodes.USER_LOGIN_SUCCESS
    ) {
        fetchUtil({
            url: "/me",
            token: localStorage.RAToken
        })
            .then((res) => {
                dispatch({
                    type: ME_SUCCESS,
                    payload: res.User,
                    Token: localStorage.RAToken,
                    code: localStorage.RACode
                });
            })
            .catch((err) => {
                errorToaster(err);
                dispatch({ type: ME_FAIL, payload: err });
            })
            .finally((_) => {
                dispatch({ type: FETCHING, payload: false });
                dispatch(setCode(localStorage.RACode));
            });
    } else if (localStorage.RAToken !== undefined) {
        dispatch(setCode(localStorage.RACode));
        dispatch({ type: FETCHING, payload: false });
    } else {
        dispatch({ type: ME_FAIL, payload: "Authorization required." });
        dispatch({ type: FETCHING, payload: false });
    }
};

export const changeView = (isAdminView, token, user) => {
    return {
        type: CHANGE_VIEW,
        payload: isAdminView,
        token,
        user
    };
};

export const forgotPassword = (email) => (dispatch) => {
    const body = JSON.stringify({
        Email: email
    });
    return fetchUtil({
        url: "/auth/password/forgot",
        method: "POST",
        body
    });
};

export const signOut = () => (dispatch, getState) => {
    const token = getState().auth.user.Token || localStorage.RAToken;
    return fetchUtil({
        url: "/signout",
        method: "POST",
        token
    })
        .then((res) => {
            if (res) {
                persistor.purge().then((res) => {
                    dispatch({ type: SIGN_OUT });
                    localStorage.clear();
                    Promise.resolve(true);
                });
            }
        })
        .catch((err) => {
            errorToaster(err);
            return Promise.reject(false);
        });
};
