import React, { Component } from "react";
import "./RadioButton.scss";

import { getRandomId } from "../../../utils/commonUtils";
import { CgRadioCheck } from 'react-icons/cg';
import { CgRadioChecked } from 'react-icons/cg';

class CompRadioButton extends Component {
    constructor(props) {
        super(props);
        this.randomId = getRandomId();
    }

    renderLabel() {
        const { label } = this.props;
        return label ? <p className="radio-button__text m-b-xs">{label}</p> : null;
    }

    renderRadioButtons() {
        const { config, dataField = "", value, onChange } = this.props;
        return config ? (
            <div>
                {config.map((radio, index) => {
                    const id = radio.id || this.randomId + index;
                    return (
                        <span key={id} className={`radio-button__container inline-flex-center ${!this.props.classes ? `m-t-xs` : ``}`}>
                            <input
                                data-field={dataField}
                                onChange={onChange}
                                id={id}
                                className="radio-button__input"
                                type="radio"
                                name={radio.name}
                                value={radio.value}
                                checked={+value === +radio.value}
                            />
                            <label htmlFor={id} className="radio-button__label inline-flex-center">

                                {/* <img
                                    className="radio-button__label-inactive"
                                    src={CgRadioCheck}
                                    alt="icon"
                                /> */}
                                <div className="radio-button__label-inactive"><CgRadioCheck /></div>
                                <div className="radio-button__label-active"><CgRadioChecked /></div>


                                {/* <img
                                    className="radio-button__label-active"
                                    src={CgRadioChecked}
                                    alt="icon"
                                /> */}
                            </label>
                            <label htmlFor={id}>{radio.label}</label>
                        </span>
                    );
                })}
            </div>
        ) : null;
    }

    render() {
        const { classes } = this.props;

        return (
            <fieldset className={`radio-button ${classes}`}>
                {this.renderLabel()}
                {this.renderRadioButtons()}
            </fieldset>
        );
    }
}

export default CompRadioButton;
