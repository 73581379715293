import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";

import "./Users.scss";
import { Button, DropdownButton, IconButton } from "../../../components/Button";
import { Table, HeadTr } from "../../../components/Table";
import Pagination from "../../../components/Pagination/Pagination";
import { addWhite } from "../../../assets/svg-icon";
import { InputField, SearchField, TypeHead, CheckBox } from "../../../components/Input";
import {
  DialogModal,
  Dialog,
  DialogContent,
  ImpersonateDialogContent
} from "../../../components/Dialog";
import {
  getUsersData,
  createUser,
  deleteUser,
  changeView,
  getDefaultCompanies,
  impersonate,
  clearUserDataListing,
  regenerateApiKey,
  getCompanyById,
  refreshDropdown,
  updateUserSegments,
  setTempAddUserPayload
} from "../../../redux/actions";
import { Spinner, LayoutLoading } from "../../../components/Loading";
import { PaginationLimit, ApplicationTypes } from "../../../config/constants";
import { spinnerWhite } from "../../../assets/svg-icon";
import { toaster, errorToaster } from "../../../utils/loggerUtils";
import DropdownSelector from "../../../components/Input/DropdownSelector/DropdownSelector";

const rowLimit = PaginationLimit.ROWS_LIMIT;
let searchingText = "",
  deleteUserId = null,
  searchQuery = false;

const dialogContent = {
  title: "Delete User",
  text: "Are you sure you would like to delete this user?",
  actionLabel: "Delete",
  actionClass: "red"
};

const USER_LABEL = {
    1: "Admin",
    2: "User",
    3: "Club Admin"
};

const USER_TYPE = {
  ADMIN: 1,
  USER: 2,
  SUBADMIN: 3,
};

const optionsForCreate = [  { value: 1, label: "Admin" },{ value: 2, label: "User" },{ value: 3, label: "Club Admin" }];
const optionsForEdit = [{ value: 2, label: "User" },{ value: 3, label: "Club Admin" }];
const filterOptions = (companies, query) => {
  const companiesData = companies.map((comapny) => {
    return { ...comapny, label: comapny.Name };
  });

  if (query)
    return companiesData.filter((i) => i.label.toLowerCase().includes(query.toLowerCase()));
  else return companiesData;
};
const IsCompanyUser = (type) => {
  return (type === USER_TYPE.USER || type === USER_TYPE.SUBADMIN)
}
const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    lineHeight: "3.6rem",
    padding: "0 1rem",
    backgroundColor: "#f9faff",
    border: "2px solid #c6d3ff",
    borderRadius: "3px",
    color: "#000",
    fontFamily: "inherit",
    fontWeight: "500",
    fontSize: "1.8rem",
    "&:hover": { borderColor: "#abbefc" }
  })
};

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addUserDialog: false,
      deleteUserDialog: false,
      editUser: null,
      searching: "",
      userPayload: {},
      companyName: "",
      userSelect: null,
      params: {
        Page: 1
      },
      regenerating: false,
      impersonateDialog: false,
      companySegments: [],
      userUpdatedSegment: []
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.codeHolder = React.createRef();
  }

  componentDidMount() {
    const { params } = this.state;
    this.props.getUsersData(params);
  }

  componentWillUnmount() {
    this.props.clearUserDataListing();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users !== this.props.users) {
      const { editUser } = this.state;
      if (editUser) {
        const { Id } = editUser;
        const updatedUser = this.props.users.find((user) => user.Id === Id);
        this.setState({
          editUser: { ...updatedUser },
          userPayload: { ...updatedUser }
        });
      }
    }
  }

  onChangeHandler(e) {
    const key = e.target.dataset.field;
    let val = e.target.value,
      userPayload = this.state.userPayload;
    userPayload[key] = val;
    this.setState(userPayload);
  }

  handleCheckboxChange = (e, type) => {
    let userPayload = {
      ...this.state.userPayload,
      ...(this.state.userPayload.Apps && { Apps: [...this.state.userPayload.Apps] })
    };

    if (!userPayload.Apps) {
      userPayload.Apps = [];
    }
    let currentAppIndex = userPayload.Apps.findIndex((data) => {
      if (typeof data === "object") {
        return data.Id === type;
      }
      return data === type;
    });
    if (e.target.checked) {
      userPayload.Apps.push(type);
    } else {
      userPayload.Apps.splice(currentAppIndex, 1);
    }
    this.setState({ userPayload });
  };

  handleSelectChange = (type) => {
    const { userPayload } = this.state;
    let { value } = type;
    value = Number(value);
    userPayload["Type"] = value;
    this.setState({ userPayload, userSelect: type });
  };

  createUser(e) {
    e.preventDefault();
    const { editUser, userPayload, searching, userUpdatedSegment, params } = this.state,
      method = editUser ? "PUT" : "POST";

    let payload = { ...userPayload };
    if (payload.Type) payload.Type = +payload.Type;

    if (!payload.Type) {
      errorToaster({ Message: "Please select a role!" });
      return;
    }
    if (!payload.CompanyId && IsCompanyUser(payload.Type)) {
      errorToaster({ Message: `Please select a company!` });
      return null;
    }

    if (IsCompanyUser(payload.Type)) {
      if (!payload.Apps || !payload.Apps.length) {
        errorToaster({ Message: `Please select an application!` });
        return null;
      } else {
        payload.Apps = payload.Apps.map((data) => {
          if (typeof data === "object") {
            return data.Id;
          } else {
            return data;
          }
        });
      }
    }

    if (editUser && payload.Email === editUser.Email) delete payload.Email;

    this.props
      .createUser(payload, method)
      .then((res) => {
        let updateSegmentPayload = {
          CompanyId: payload.CompanyId,
          UserId: editUser ? editUser.Id : res.Id,
          SegmentId: userUpdatedSegment.map((eachSegment) => eachSegment.Id)
        }
        this.props.updateUserSegments(updateSegmentPayload).then((response) => {
          this.props.getUsersData(params);
          toaster(`User have been ${editUser ? "Updated!" : "Created!"}`);
          this.toggleDialog();
          if (searching.length !== 0 && method === "POST") {
            this.setState({ searching: "" }, () => {
              this.searchSubmit(null, "search");
            });
          } else {
            if (!editUser) this.getUserListing();
          }
        }).catch((err) => errorToaster(err));
      })
      .catch((err) => errorToaster(err));
  }

  getUserListing(Page) {
    let { params } = this.state;

    if (Page) params = { ...params, Page };

    this.props.getUsersData(params);
    this.setState({ params });
    searchQuery = false;
  }

  toggleDialog(e, defaultPayload = {}) {
    const addUserDialog = this.state.addUserDialog;
    this.setState({ addUserDialog: !addUserDialog, userUpdatedSegment: [], companySegments: [] });
    if (!addUserDialog) {
      this.setState({
        editUser: null,
        userPayload: { ...defaultPayload },
        companyName: "",
        userSelect: null,
      });
      if (Object.keys(defaultPayload).length > 0) {
        this.getSegmentsOptions(defaultPayload.CompanyId, true)
        this.props.refreshDropdown();
      }
    }
  }

  editUserDialog(user) {
    this.setState({
      editUser: user,
      addUserDialog: true,
      userPayload: { ...user },
      companyName: null
    });
    this.getSegmentsOptions(user.CompanyId);
    this.getDefualtSegments(user)
  }

  deleteUserDialogToggle(user) {
    this.setState({
      deleteUserDialog: !this.state.deleteUserDialog
    });
    deleteUserId = user ? user.Id : null;
  }

  actionDeleteUser() {
    this.props
      .deleteUser(deleteUserId)
      .then((res) => {
        this.getUserListing();
        toaster("The user have been deleted!");
        this.deleteUserDialogToggle();
      })
      .catch((err) => errorToaster(err));
  }

  handleImpersonateClick(user) {
    if (
      ((this.isCompanySelected(ApplicationTypes.RANGE_CONNECT, user) &&
      this.isCompanySelected(ApplicationTypes.RANGE_BOOTH, user)) || 
      (this.isCompanySelected(ApplicationTypes.RANGE_CONNECT, user) &&
      this.isCompanySelected(ApplicationTypes.WEB_CHAT, user)) || 
      (this.isCompanySelected(ApplicationTypes.RANGE_BOOTH, user) &&
      this.isCompanySelected(ApplicationTypes.WEB_CHAT, user)))
    ) {
      this.setState({ impersonateDialog: true, userPayload: { ...user } });
    } else if (this.isCompanySelected(ApplicationTypes.RANGE_CONNECT, user)) {
      this.impersonateUser(user.Id, ApplicationTypes.RANGE_CONNECT);
    } else if (this.isCompanySelected(ApplicationTypes.WEB_CHAT, user)) {
      this.impersonateUser(user.Id, ApplicationTypes.WEB_CHAT);
    } else {
      this.impersonateUser(user.Id, ApplicationTypes.RANGE_BOOTH);
    }
  }

  impersonateUser = (id, appId) => {
    this.props
      .impersonate(id, appId)
      .then((res) => {
        let redirectUrl = `${res.RedirectUrl}/verify/${res.Token}?isAdmin=true&url=${window.origin}`;
        window.open(redirectUrl)
        // window.location.replace(redirectUrl);
      })
      .catch((err) => { });
  };

  impersonateDialogButtonClick = (appId) => {
    const { userPayload } = this.state;

    this.impersonateUser(userPayload.Id, appId);
    this.handleImpersonateDialogClose();
  };

  handleImpersonateDialogClose = () => {
    this.setState({ impersonateDialog: false });
  };

  handleRegenerateClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { regenerateApiKey } = this.props;
    this.setState({ regenerating: true });
    regenerateApiKey(id)
      .then((res) => this.setState({ regenerating: false }))
      .catch((err) => this.setState({ regenerating: false }));
  };

  handleKeyCopy = (apiKey) => {
    if (apiKey.length !== 0) {
      const text = this.codeHolder.current;
      text.focus();
      text.select();

      try {
        let success = document.execCommand("copy");
        if (success) {
          toaster("Copied");
        }
      } catch (error) {
        errorToaster({ Message: `Unable to copy!` });
      }
    }
  };

  renderDeleteDialog() {
    const { deleteUserRequest } = this.props;
    return (
      <Dialog>
        <DialogContent
          icon={dialogContent.icon}
          title={dialogContent.title}
          text={dialogContent.text}
          actionLabel={dialogContent.actionLabel}
          actionClass={dialogContent.actionClass}
          loading={deleteUserRequest}
          action={this.actionDeleteUser.bind(this)}
          cancel={this.deleteUserDialogToggle.bind(this)}
        />
      </Dialog>
    );
  }

  handleInputChange = (newValue) => {
    const companyName = newValue;
    this.setState({ companyName, userUpdatedSegment: [] });
    return companyName;
  };

  onChangeSelect = (val) => {
    let userPayload = this.state.userPayload;
    userPayload.CompanyId = val ? val.Id : null;
    this.setState(userPayload);
    this.props.refreshDropdown();
    this.getSegmentsOptions(userPayload.CompanyId)
  };

  loadOptions = (query, callback) => {
    const params = query ? { Q: query, Page: 1 } : null;
    this.props.getDefaultCompanies(params).then((res) => {
      callback(filterOptions(res, query));
      this.setEditCompanyName(res);
    });
  };

  setEditCompanyName(companies) {
    const { companyName, editUser } = this.state;
    if (editUser && companyName === null) {
      const getCompany = companies.find((company) => company.Id === editUser.CompanyId);
      this.setState({ companyName: getCompany ? getCompany.Name : "" });
    }
  }

  isCompanySelected = (type, user = null) => {
    const userPayload = user ? user : this.state.userPayload;

    if (userPayload.Apps) {
      return userPayload.Apps.find((data) => {
        if (typeof data === "object") {
          return type === data.Id;
        } else {
          return type === data;
        }
      });
    }
    return false;
  };
  transformSegmentObjectForDropdown = (segmentsArray) => {
    let segmentResponse = segmentsArray.map((eachSegment) => {
      return { ...eachSegment, label: eachSegment.Title, value: eachSegment.Id };
    })
    return segmentResponse;
  }
  getDefualtSegments = (editUser) => {
    let defualtOption = this.transformSegmentObjectForDropdown(editUser.UserAllowedSegments);
    if (this.state.userUpdatedSegment && this.state.userUpdatedSegment.length !== defualtOption.length) {
      this.setState({
        userUpdatedSegment: defualtOption
      })
    }
    return defualtOption;
  }
  handleDropdownOnChange = (values, actionData) => {
    this.setState({
      userUpdatedSegment: values
    })
  }
  getSegmentsOptions = (companyId, fillName = false) => {
    const { getCompanyById } = this.props;
    if (companyId) {
      getCompanyById(companyId).then((response) => {
        const data = {
          companySegments: this.transformSegmentObjectForDropdown(response.Company.Segments)
        };
        if (fillName) data.companyName = response.Company.Name;
        this.setState(data)
      })
    } else {
      this.setState({
        userUpdatedSegment: [],
        companySegments: []
      })
    }
  }
  renderDialog() {
    const { createUserRequest } = this.props;
    const { editUser, userPayload, companyName, regenerating } = this.state,
      title = editUser ? "Edit User" : "Create a New User",
      btnLabel = editUser ? "Save" : "Create User",
      companyLabel = companyName === null ? "" : companyName;
    const selectValue = editUser
      ? { value: userPayload.Type, label: USER_LABEL[userPayload.Type] }
      : null;
    const currentCompanyName =
      editUser && editUser.Company ? editUser.Company.Name : "SEARCH COMPANIES";
    userPayload.ApiKey = userPayload.ApiKey === null ? "" : userPayload.ApiKey;
    return (
      <DialogModal classes="scrolling m-w-948" closeDialog={this.toggleDialog.bind(this)}>
        <form className="m-a user-dialog" onSubmit={this.createUser.bind(this)}>
          <h2 className="m-b-sm f-45">{title}</h2>
          <div className="flex-sa">
            <InputField
              data-field="Name"
              value={userPayload.Name}
              onChange={this.onChangeHandler}
              label="FULL NAME"
              placeHolder="Full Name"
              required
              classes="flex-45"
            />
            <fieldset
              className={`type-head flex-45 m-t-42 ${IsCompanyUser(userPayload.Type) || !editUser ? "" : "disabled"}`}
            >
              <div className="select-box__border">
                <Select
                  value={editUser ? selectValue : this.state.userSelect}
                  onChange={this.handleSelectChange}
                  options={!editUser ? optionsForCreate : optionsForEdit}
                  isDisabled={IsCompanyUser(userPayload.Type) || !editUser ? false : true}
                  inputId="userSelect"
                  styles={customStyles}
                  placeholder="Assign Role..."
                />
              </div>
            </fieldset>
          </div>
          <div className="flex-sa">
            <InputField
              data-field="Email"
              value={userPayload.Email}
              onChange={this.onChangeHandler}
              type="email"
              label="EMAIL ADDRESS"
              placeHolder="Email Address"
              required
              classes="flex-45"
            />
            <InputField
              data-field="Password"
              value={userPayload.Password}
              onChange={this.onChangeHandler}
              type="password"
              label="PASSWORD"
              placeHolder="******"
              required={!editUser}
              classes="flex-45"
            />
          </div>
          <div className="flex-sa">
            <TypeHead
              inputValue={companyLabel}
              loadOptions={this.loadOptions}
              placeholder={editUser ? currentCompanyName : "SEARCH COMPANIES"}
              onChange={this.onChangeSelect}
              onInputChange={this.handleInputChange}
              isDisabled={editUser || userPayload.Type === USER_TYPE.ADMIN}
              classes={
                editUser || userPayload.Type === USER_TYPE.ADMIN
                  ? "disabled flex-45"
                  : "flex-45"
              }
              styles={customStyles}
              label="Company Name"
              showBorder={true}
            />
            <DropdownSelector
              inputValue={companyLabel}
              loadOptions={this.state.companySegments}
              placeholder={"Select"}
              onChange={this.handleDropdownOnChange}
              fetching={this.props.fetchingCompanyData}
              value={this.state.userUpdatedSegment}
              classes={"flex-45"}
              styles={customStyles}
              label="Can Send Message To"
              showBorder={true}
            />
          </div>


          {editUser && IsCompanyUser(userPayload.Type) && (
            <div className="flex-center">
              <div
                className="relative user-input"
                onClick={() => this.handleKeyCopy(userPayload.ApiKey)}
              >
                <InputField
                  value={userPayload.ApiKey}
                  readOnly
                  title="copy"
                  placeHolder="Api key"
                />
                <span className="user-input__line" />
                <span className="user-input__icon">
                  <IconButton
                    icon={spinnerWhite}
                    classes={regenerating ? "loading" : ""}
                    clickHandler={(e) =>
                      this.handleRegenerateClick(e, userPayload.Id)
                    }
                  />
                </span>
                <textarea
                  ref={this.codeHolder}
                  className="user-input__holder"
                  value={userPayload.ApiKey}
                  readOnly
                />
              </div>
            </div>
          )}
          <div className="flex-center">
            <fieldset className="field-set flex-45 users-checkboxes chat-checkboxes">
              <label className="gray-text bold user-modal-label">Has access to</label>
              <div className={userPayload.Type === USER_TYPE.ADMIN ? "disable" : ""}>
                <CheckBox
                  label={
                    <>
                      CLUB<strong>BOOTH</strong>
                    </>
                  }
                  checked={!!this.isCompanySelected(ApplicationTypes.RANGE_BOOTH)}
                  changeHandler={(e) =>
                    this.handleCheckboxChange(e, ApplicationTypes.RANGE_BOOTH)
                  }
                  classes="m-r-xs"
                />
                <CheckBox
                  label={
                    <>
                      CLUB<strong>CHAT</strong>
                    </>
                  }
                  checked={
                    !!this.isCompanySelected(ApplicationTypes.CLUB_CHAT)
                  }
                  changeHandler={(e) =>
                    this.handleCheckboxChange(e, ApplicationTypes.CLUB_CHAT)
                  }
                  classes="m-r-xs"
                />

                <CheckBox
                  label={
                    <>
                      WEB<strong>CHAT</strong>
                    </>
                  }
                  checked={
                    !!this.isCompanySelected(ApplicationTypes.WEB_CHAT)
                  }
                  changeHandler={(e) =>
                    this.handleCheckboxChange(e, ApplicationTypes.WEB_CHAT)
                  }
                />
              </div>
            </fieldset>
          </div>
          <div className="flex-center m-t">
            <Button
              loading={createUserRequest}
              type="submit"
              classes="green"
              label={btnLabel}
            />
            {editUser ? (
              <Button
                clickHandler={this.toggleDialog.bind(this)}
                classes="m-l-xs"
                label="Cancel"
              />
            ) : null}
          </div>
        </form>
      </DialogModal>
    );
  }

  renderImpersonateDialog = () => {
    return (
      <DialogModal classes="impersonate" closeDialog={this.handleImpersonateDialogClose}>
        <ImpersonateDialogContent
          onImpersonateDialogButtonClick={this.impersonateDialogButtonClick}
          userPayload={this.state.userPayload}
          fromUser={true}
        />
      </DialogModal>
    );
  };

  renderTableData() {
    const { fetching, users } = this.props;
    return !fetching && !users.length ? (
      <tr>
        <td colSpan={4} className="no-data bold secondary-text">
          {searchQuery ? "Sorry, No data match for this criteria" : "No data yet"}
        </td>
      </tr>
    ) : (
      users.map((user, i) => (
        <tr key={i}>
          <td>{user.Name}</td>
          <td>{user.Email}</td>
          <td>{user.Company ? user.Company.Name : ""}</td>
          <td className="right">
            <DropdownButton>
              <button onClick={this.editUserDialog.bind(this, user)}>
                Edit Account
              </button>
              {IsCompanyUser(user.Type) && (
                <button onClick={this.handleImpersonateClick.bind(this, user)}>
                  Impersonate
                </button>
              )}
              <button
                className="danger-text"
                onClick={this.deleteUserDialogToggle.bind(this, user)}
              >
                Delete User
              </button>
            </DropdownButton>
          </td>
        </tr>
      ))
    );
  }

  onChangeSearch(e) {
    this.setState({ searching: e.target.value });
    if (e.target.value.length === 0) {
      this.setState({ searching: "" }, () => {
        this.searchSubmit(null, "search");
      });
    }
  }

  searchSubmit(e, type = "form") {
    type === "form" && e.preventDefault();
    let { searching } = this.state,
      params = { Page: 1 };
    if (searchingText === searching) return false;
    if (searching) params.Q = searching;
    this.props.getUsersData(params);
    this.setState({ params });
    searchingText = searching;
    searchQuery = !!searching;
  }

  renderTable() {
    const { fetching, totalUsers, impersonateRequest } = this.props;
    const { params } = this.state;
    return (
      <div className="relative">
        <Table classes="no-scroll">
          <HeadTr>
            <th>Name</th>
            <th>Email Address</th>
            <th>Company Name</th>
            <th>&nbsp;</th>
          </HeadTr>
          <tbody>{this.renderTableData()}</tbody>
        </Table>
        {fetching || impersonateRequest ? <LayoutLoading /> : null}
        {totalUsers > rowLimit ? (
          <Pagination
            total={totalUsers}
            active={params.Page}
            clickHandler={this.getUserListing.bind(this)}
          />
        ) : null}
      </div>
    );
  }

  renderUserListing() {
    const { fetching, users } = this.props;
    return fetching && !users.length ? (
      <div className="flex-center p-y-xlg">
        <Spinner classes="green large" />
      </div>
    ) : (
      this.renderTable()
    );
  }

  render() {
    const { addUserDialog, deleteUserDialog, impersonateDialog } = this.state;

    if (this.props.tempAddNewUserPayload) {
      this.toggleDialog(null, this.props.tempAddNewUserPayload)
      this.props.setTempAddUserPayload(null)
    }

    return (
      <section>
        <div className="flex-end-center m-b">
          <Button
            icon={addWhite}
            label="Create New User"
            classes="green medium"
            clickHandler={this.toggleDialog.bind(this)}
          />
        </div>
        <div className="white-box">
          <div className="table-search">
            <SearchField
              onSubmit={this.searchSubmit.bind(this)}
              onChange={this.onChangeSearch.bind(this)}
              value={this.state.searching}
              placeHolder="Search User... "
            />
          </div>
          {this.renderUserListing()}
        </div>
        {addUserDialog ? this.renderDialog() : null}
        {deleteUserDialog ? this.renderDeleteDialog() : null}
        {impersonateDialog ? this.renderImpersonateDialog() : null}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  fetching: state.users.fetching,
  createUserRequest: state.users.createUserRequest,
  deleteUserRequest: state.users.deleteUserRequest,
  impersonateRequest: state.users.impersonateRequest,
  users: state.users.data,
  totalUsers: state.users.totalUsers,
  defaultDropDownCompanies: state.companies.defaultDropDownCompanies,
  fetchingCompanyData: state.companies.fetchingCompanyData,
  tempAddNewUserPayload: state.users.tempAddNewUserPayload,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setTempAddUserPayload: (params) => dispatch(setTempAddUserPayload(params)),
    getUsersData: (params) => dispatch(getUsersData(params)),
    createUser: (data, method) => dispatch(createUser(data, method)),
    deleteUser: (id) => dispatch(deleteUser(id)),
    changeView: (isAdminView, token, user) => dispatch(changeView(isAdminView, token, user)),
    getDefaultCompanies: (params) => dispatch(getDefaultCompanies(params)),
    impersonate: (id, appId) => dispatch(impersonate(id, appId)),
    clearUserDataListing: () => dispatch(clearUserDataListing()),
    regenerateApiKey: (id) => dispatch(regenerateApiKey(id)),
    getCompanyById: (id) => dispatch(getCompanyById(id)),
    refreshDropdown: () => dispatch(refreshDropdown()),
    updateUserSegments: (data) => dispatch(updateUserSegments(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
