import {
    COMPANIES_DATA_REQUEST,
    COMPANIES_DATA_SUCCESS,
    COMPANIES_DATA_FAIL,
    CREATE_COMPANY_REQUEST,
    CREATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_SUCCESS,
    CREATE_COMPANY_FAIL,
    DELETE_COMPANY_REQUEST,
    DELETE_COMPANY_SUCCESS,
    DELETE_COMPANY_FAIL,
    DEFAULT_COMPANIES_DATA_REQUEST,
    DEFAULT_COMPANIES_DATA_SUCCESS,
    DEFAULT_COMPANIES_DATA_FAIL,
    CLEAR_COMPANIES_DATA_LISTING,
    TIME_ZONE_SUCCESS,
    REFRESH_DROPDOWN_OPTIONS,
    FETCHING_COMPANY_STATUS,
    SET_WEBCHAT_FETCHING,
} from "../actions";
import { CompanyPhoneTypes } from "../../config/constants";

const initialState = {
    fetching: false,
    createCompanyRequest: false,
    deleteCompanyRequest: false,
    defaultCompanyRequest: false,
    error: "",
    totalCompanies: 0,
    defaultDropDownCompanies: [],
    data: [],
    companyTimeZones: [],
    refreshDropdown: false,
    fetchingCompanyData: false,
    webChatsFetching: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case COMPANIES_DATA_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case CREATE_COMPANY_REQUEST:
            return {
                ...state,
                createCompanyRequest: true
            };
        case DELETE_COMPANY_REQUEST:
            return {
                ...state,
                deleteCompanyRequest: true
            };
        case DEFAULT_COMPANIES_DATA_REQUEST:
            return {
                ...state,
                defaultCompanyRequest: true
            };
        case COMPANIES_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload ? action.payload.Companies : [],
                totalCompanies: action.payload ? action.payload.TotalCompanies : 0
            };
        case UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                createCompanyRequest: false,
                data: (() => {
                    return state.data.map((data) => {
                        if (data.Id === action.payload.Id) {
                            const updated = action.payload;
                            if (
                                !updated.PhoneNumber &&
                                updated.PhoneType === CompanyPhoneTypes.TOLL_FREE
                            ) {
                                delete data.PhoneNumber;
                            }
                            return { ...data, ...updated };
                        }
                        return data;
                    });
                })(),
                defaultDropDownCompanies: state.defaultDropDownCompanies.map((company) => {
                    if (company.Id === action.payload.Id) {
                        return {
                            ...action.payload
                        };
                    }
                    return { ...company };
                })
            };
        case DEFAULT_COMPANIES_DATA_SUCCESS:
            return {
                ...state,
                defaultDropDownCompanies: (() => {
                    let companies = [...state.defaultDropDownCompanies],
                        payload = action.payload ? action.payload.Companies : [];
                    payload.forEach((company) => {
                        let temp = companies.find((com) => com.Id === company.Id);
                        if (!temp) companies.unshift(company);
                    });
                    return companies;
                })()
            };
        case DEFAULT_COMPANIES_DATA_FAIL:
            return {
                ...state,
                defaultCompanyRequest: false
            };
        case TIME_ZONE_SUCCESS:
            return {
                ...state,
                companyTimeZones: action.payload.TimeZones
            };
        case COMPANIES_DATA_FAIL:
            return {
                ...state,
                fetching: false
            };
        case CREATE_COMPANY_SUCCESS:
        case CREATE_COMPANY_FAIL:
            return {
                ...state,
                createCompanyRequest: false
            };
        case DELETE_COMPANY_SUCCESS:
            return {
                ...state,
                deleteCompanyRequest: false,
                defaultDropDownCompanies: state.defaultDropDownCompanies.filter((company) => {
                    return company.Id !== action.id;
                })
            };
        case DELETE_COMPANY_FAIL:
            return {
                ...state,
                deleteCompanyRequest: false
            };
        case CLEAR_COMPANIES_DATA_LISTING:
            return {
                ...state,
                data: [],
                totalCompanies: 0
            };
        case REFRESH_DROPDOWN_OPTIONS:
            return {
                ...state,
                refreshDropdown: !state.refreshDropdown
            }
        case FETCHING_COMPANY_STATUS:
            return {
                ...state,
                fetchingCompanyData: action.payload
            }
        case SET_WEBCHAT_FETCHING:
            return { ...state, webChatsFetching: action.payload }
        default:
            return state;
    }
};
