import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./App.scss";
import { Account } from "./views/Account";
import { Dashboard } from "./views/Dashboard";
import "react-toastify/dist/ReactToastify.css";

function App() {
    const toastProps = {
        autoClose: 3000,
        enableMultiContainer: true,
        closeButton: false,
        hideProgressBar: true,
        style: { marginTop: "1.6em", minWidth: "31.625em", right: "0em" },
        className: "custom-toast-container"
    };
    return (
        <div id="range-booth">
            <ToastContainer
                containerId="success"
                toastClassName="custom-toast-message success-message"
                {...toastProps}
            />
            <ToastContainer
                containerId="error"
                toastClassName={"custom-toast-message error-message"}
                {...toastProps}
            />
            <BrowserRouter>
                <Switch>
                    <Route path="/account" name="account" component={Account} />
                    <Route path="/dashboard" name="dashboard" component={Dashboard} />
                    <Redirect to="/account/login" />
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
