import React from "react";

import "./Dialog.scss";
import { ApplicationTypes } from "../../config/constants";
import { warningAlert } from "../../assets/svg-icon";
import ClubBooth from "../../assets/images/ClubBooth.png";
import ClubChat from "../../assets/images/ClubChat.png";
import WebChat from "../../assets/images/WebChat.png";
import { LayoutLoading } from "../../components/Loading";

const RenderButton = ({ onClick, icon, alt }) => {
    return (
        <div className="impersonate-dialog__section-btn" onClick={onClick}>
            <div className="btn-img">
                <img src={icon} alt={alt} />
            </div>
        </div>
    );
};

const ImpersonateDialogContent = (props) => {
    const {
        onImpersonateDialogButtonClick,
        title = "Impersonate User",
        isLoading = false,
        userPayload,
        fromUser
    } = props;
    
    const canShowButton = (type) => {
        if (!fromUser) {
            return true;
        }
        if (userPayload && userPayload.Apps) {
            let isCompanyExist = userPayload.Apps.find((data) => data.Id === type);
            if (fromUser && isCompanyExist) {
                return true;
            }
            return false;
        }
        return false;
    };

    return (
        <div className="impersonate-dialog">
            <div className="impersonate-dialog__image">
                <img src={warningAlert} alt="warning alert" />
            </div>
            <div className="impersonate-dialog__text">
                <h4>{title}</h4>
            </div>
            <div className="impersonate-dialog__section">
                {canShowButton(ApplicationTypes.WEB_CHAT) && (
                    <RenderButton
                        onClick={() => onImpersonateDialogButtonClick(ApplicationTypes.WEB_CHAT)}
                        icon={WebChat}
                        alt="web-chat-logo"
                    />
                )}
                {canShowButton(ApplicationTypes.RANGE_BOOTH) && (
                    <RenderButton
                        onClick={() => onImpersonateDialogButtonClick(ApplicationTypes.RANGE_BOOTH)}
                        icon={ClubBooth}
                        alt="club-booth-logo"
                    />
                )}
                {canShowButton(ApplicationTypes.RANGE_CONNECT) && (
                    <RenderButton
                        onClick={() =>
                            onImpersonateDialogButtonClick(ApplicationTypes.RANGE_CONNECT)
                        }
                        icon={ClubChat}
                        alt="range-connect-logo"
                    />
                )}
            </div>
            {isLoading && <LayoutLoading parentClass="impersonate-dialog" />}
        </div>
    );
};

export default ImpersonateDialogContent;
