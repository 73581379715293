import React, { Component } from "react";
import { PaginationLimit } from "../../config/constants";

import "./Pagination.scss"
const rowLimit = PaginationLimit.ROWS_LIMIT,
    buttonLimit = PaginationLimit.PAGINATION_BUTTONS_LIMIT;

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start : 1,
            end:  null
        };
    }

    componentDidMount() {
        const {start} = this.state;
        this.setStartAndEndIndex(start);
    }


    click(page){
        const {start, end} = this.state;
        const {clickHandler, total} = this.props,
            totalPages = Math.ceil(total/rowLimit);
        clickHandler(page);

        if(start === page && start > 1){
            let startIndex = (start -(buttonLimit - 1));
            startIndex = startIndex < 1 ?  1 : startIndex;
            this.setStartAndEndIndex(startIndex);
        }
        else if(end === page && end < totalPages){
            let startIndex = end + (buttonLimit - 1);
            startIndex = startIndex > totalPages ? (totalPages - (buttonLimit - 1)) : startIndex;
            this.setStartAndEndIndex(startIndex);
        }
    }

    setStartAndEndIndex(start){
        const {total} = this.props,
            totalPages = Math.ceil(total/rowLimit);
        let  end = (start + (buttonLimit - 1)) ;
        end = totalPages < end ?  totalPages : end;

        this.setState({start, end});
    }

    renderPages(){
        const {start, end} = this.state,
            {active} = this.props;
        let pages = [];
        for (let i = start; i <= end; i++) {
            pages.push(
                <li className={`page-item ${active === i ? 'active' : ''}`} key={i}>
                    <button className="page-link" disabled={active === i} onClick={() => this.click(i)}>{i}</button>
                </li>
            )
        }
        return pages;
    }

    render() {
        const {active, total} = this.props,
            totalPages = Math.ceil(total/rowLimit);
        return  totalPages > 1 ?(
            <nav className="p">
                <ul className="pagination flex-s-center">
                    <li className="page-item">
                        <button id="previous-page" className="page-link flex-center" disabled={active === 1} onClick={() => this.click(active - 1)}>
                            <img className="page-arrow rotate" src="https://s3.amazonaws.com/koderlabs.com/lda/PaginationNext.svg" alt="" />
                        </button>
                    </li>

                    {this.renderPages()}

                    <li className="page-item flex-center">
                        <button id="next-page" className="page-link" disabled={active === totalPages} onClick={() => this.click(active + 1)}>
                            <img className="page-arrow" src="https://s3.amazonaws.com/koderlabs.com/lda/PaginationNext.svg" alt="" />
                        </button>
                    </li>
                </ul>
            </nav>
        ) : null;
    }
}

export default Pagination;
