import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";

import "./TypeHead.scss";

class TypeHead extends Component {
  render() {
    const {
      loadOptions,
      onChange,
      placeholder = "search",
      inputValue = "",
      onInputChange,
      isDisabled,
      classes = "",
      styles,
      label = false,
      showBorder = false
    } = this.props;

    let asyncSelect = (
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        placeholder={placeholder}
        onChange={onChange}
        defaultOptions
        inputValue={inputValue}
        onInputChange={onInputChange}
        isClearable
        isSearchable
        isDisabled={isDisabled}
        styles={styles}
      />
    );
    return (
      <fieldset className={`type-head ${classes}`}>
        {label && (
          <label className="gray-text bold user-modal-label">{label}</label>
        )}
        {showBorder ? (
          <div className="select-box__border">{asyncSelect}</div>
        ) : (
          asyncSelect
        )}
      </fieldset>
    );
  }
}

export default TypeHead;
