import { LOGIN_FAIL, ME_FAIL, ME_SUCCESS, SET_CODE, CHANGE_VIEW, FETCHING } from "../actions";
import { AuthenticationCodes, AuthenticationTypes } from "../../config/constants";

const initialState = {
    fetching: false,
    error: "",
    code: "",
    isAuthenticated: false,
    user: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_FAIL:
        case ME_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                fetching: false,
                error: action.payload
            };
        case ME_SUCCESS:
            return {
                ...state,
                error: "",
                code: action.code,
                isAdmin: action.payload.Type === AuthenticationTypes.ADMIN,
                isAdminView: action.payload.Type === AuthenticationTypes.ADMIN,
                isAuthenticated: true,
                user: { ...state.user, ...action.payload, Token: action.Token }
            };
        case SET_CODE:
            return {
                ...state,
                code: action.payload,
                isAuthenticated: action.payload === AuthenticationCodes.USER_LOGIN_SUCCESS
            };

        case CHANGE_VIEW:
            return {
                ...state,
                isAdminView: action.payload,
                assumeIdentity: action.token,
                assumeUser: action.user
            };
        case FETCHING:
            return {
                ...state,
                fetching: action.payload
            };

        default:
            return state;
    }
};
