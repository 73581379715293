export const FormFieldSlug = {
    email: 3,
    birthday: 4,
    gender: 5,
    memberNo: 6
}
export const FormIds = {
    OnBoard: 1
}
export const SegmentType = {
    NoSegment: 0,
    ActualSegment: 1,
}