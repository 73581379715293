import {
    USERS_DATA_REQUEST,
    USERS_DATA_SUCCESS,
    USERS_DATA_FAIL,
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    CREATE_USER_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    IMPERSONATE_USER_REQUEST,
    IMPERSONATE_USER_SUCCESS,
    IMPERSONATE_USER_FAIL,
    CLEAR_USER_DATA_LISTING,
    USER_API_KEY_SUCCESS,
    CHANGE_IMPERSONATE_MODAL_VIEW,
    SET_TEMP_ADD_USER_PAYLOAD
} from "../actions";

const initialState = {
    fetching: false,
    createUserRequest: false,
    deleteUserRequest: false,
    impersonateRequest: false,
    showImpersonateModal: false,
    error: "",
    totalUsers: 0,
    data: [],
    tempAddNewUserPayload: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USERS_DATA_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case CREATE_USER_REQUEST:
            return {
                ...state,
                createUserRequest: true
            };
        case DELETE_USER_REQUEST:
            return {
                ...state,
                deleteUserRequest: true
            };
        case USERS_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload && action.payload.Users ? action.payload.Users : [],
                totalUsers: action.payload.TotalUsers
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                createUserRequest: false,
                data: (() => {
                    return state.data.map((data) => {
                        if (data.Id === action.payload.Id) {
                            const updated = action.payload;
                            return { ...data, ...updated };
                        }
                        return data;
                    });
                })()
            };
        case USERS_DATA_FAIL:
            return {
                ...state,
                fetching: false
            };
        case CREATE_USER_SUCCESS:
        case CREATE_USER_FAIL:
            return {
                ...state,
                createUserRequest: false
            };
        case DELETE_USER_SUCCESS:
        case DELETE_USER_FAIL:
            return {
                ...state,
                deleteUserRequest: false
            };
        case IMPERSONATE_USER_REQUEST:
            return {
                ...state,
                impersonateRequest: true
            };
        case IMPERSONATE_USER_SUCCESS:
        case IMPERSONATE_USER_FAIL:
            return {
                ...state,
                impersonateRequest: false
            };
        case CLEAR_USER_DATA_LISTING:
            return {
                ...state,
                data: [],
                totalUsers: 0
            };
        case USER_API_KEY_SUCCESS:
            return {
                ...state,
                data: state.data.map((user) => {
                    if (user.Id === action.payload.id) {
                        return {
                            ...user,
                            ApiKey: action.payload.apiKey
                        };
                    }
                    return user;
                })
            };
        case CHANGE_IMPERSONATE_MODAL_VIEW:
            return {
                ...state,
                showImpersonateModal: action.payload
            };
        case SET_TEMP_ADD_USER_PAYLOAD:
            return { ...state, tempAddNewUserPayload: action.payload }
        default:
            return state;
    }
};
