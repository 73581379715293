import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Button.scss";
import { Spinner } from "../../Loading";

class Button extends Component {
  renderIcon() {
    const { icon, iconWidth } = this.props;
    return icon ? (
      <span className="inline-flex-center icon">
        <img style={{ width: iconWidth || "100%" }} src={icon} alt="icon" />
      </span>
    ) : null;
  }

  render() {
    const {
      label = "submit",
      classes = "primary-btn",
      to,
      type = "button",
      clickHandler,
      loading = false,
      loadingClass = "",
      disabled,
      ...rest
    } = this.props;
    return to ? (
      <Link
        to={to}
        className={`inline-flex-center primary-btn ${classes}`}
        onClick={clickHandler}
        {...rest}
      >
        {this.renderIcon()}
        {label}
      </Link>
    ) : type === "file" ? (
      <div className={`file-btn primary-btn ${classes}`}>
        <input disabled={loading || disabled} type="file" onChange={clickHandler} />
        {this.renderIcon()}
        <span>{label}</span>
        {loading ? <Spinner classes={`small m-l-xs ${loadingClass}`} /> : null}
      </div>
    ) : (
      <button
        type={type}
        className={`primary-btn ${classes}`}
        disabled={loading || disabled}
        onClick={clickHandler}
      >
        {this.renderIcon()}
        <span>{label}</span>
        {loading ? <Spinner classes={`small m-l-xs ${loadingClass}`} /> : null}
      </button>
    );
  }
}

export default Button;
